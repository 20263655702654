import { Link } from "@metablock/react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import DLFactory from "../../Components/DescriptionList";
import { formatDateTime } from "../../utils/dates";
import BlockActions from "./BlockActions";
import BlockEdit from "./BlockEdit";
import BlockCertificate from "./Certificate";

const BlockMain = (props: any) => {
  const { block, cfg } = props;
  const { space } = block;

  const Row = DLFactory({ sm: 3 });

  const Html = () => {
    return (
      <>
        <Row entry="Live Url">
          <MuiLink href={cfg.config.live_url}>{cfg.config.live_url}</MuiLink>
        </Row>
        <Row entry="Asset Url">
          <MuiLink href={cfg.config.assets_url}>
            {cfg.config.assets_url}
          </MuiLink>
        </Row>
      </>
    );
  };

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardHeader
          title={
            <Typography variant="h5">
              <MuiLink href={block.url}>{block.url}</MuiLink>
            </Typography>
          }
        />
        <CardContent>
          <Row entry="Space">
            <Link to={`/spaces/${space.org_name}/${space.name}`}>
              {space.name}
            </Link>
          </Row>
          <Row entry="Block ID">
            <code>{block.id}</code>
          </Row>
          <Row entry="Last Deployed">
            {cfg.config.deployed
              ? formatDateTime(new Date(cfg.config.deployed))
              : "No deployments yet"}
          </Row>
          <Row entry="Type">{block.html ? "HTML" : "Back-end"}</Row>
          {block.html ? <Html /> : null}
        </CardContent>
      </Card>
      <BlockCertificate block={block} />
      <BlockEdit block={block} sx={{ mb: 2 }} />
      <BlockActions block={block} />
    </>
  );
};

export default BlockMain;
