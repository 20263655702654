import { Extension } from "@metablock/core";
import { Link, NotFound, RouteComponent } from "@metablock/react";
import ExtensionSvg from "@metaweb/icons/extension.svg";
import { useStores } from "@metaweb/stores";
import CheckIcon from "@mui/icons-material/Check";
import IdIcon from "@mui/icons-material/DeviceHub";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { ExtensionApp, useMetablockApp } from "../../Extensions";
import ErrorFallback from "../Components/ErrorFallback";
import Header from "../Components/Header";
import List from "../Components/List";
import TabRoutes from "../Components/TabRoutes";
import Deliveries from "./Deliveries";
import Overview from "./Overview";
import Plugins from "./Plugins";
import OrgExtensionUpdate from "./Update";

const ExtensionIcon = (props: any) => {
  const { width } = props;
  return <ExtensionSvg width={width} height={width} viewBox="0 0 22 22" />;
};

const OrgExtensionHome = () => {
  const { extensionId } = useParams() as { extensionId: string };
  const { metablock } = useStores();
  const app = useMetablockApp({
    baseUrl: `/app/extensions/${extensionId}`,
    routes: [
      {
        label: "overview",
        url: "",
        Component: Overview,
      },
      {
        label: "settings",
        url: "settings",
        Component: OrgExtensionUpdate,
      },
      {
        label: "plugins",
        url: "plugins",
        Component: Plugins,
      },
      {
        label: "deliveries",
        url: "deliveries",
        Component: Deliveries,
      },
    ],
  });

  const { loading, value } = useAsync(async () => {
    return await metablock.extensions.get(extensionId);
  }, [extensionId]);

  if (loading) return null;
  if (!value) return <NotFound />;
  return <OrgExtensionApp app={app} extension={value as Extension} />;
};

const OrgExtensionApp = ({
  app,
  extension,
}: {
  app: ExtensionApp;
  extension: Extension;
}) => {
  const Tabs = (opts: any) => {
    const { Component, url, ...props } = opts;
    return (
      <TabRoutes
        routes={app.getNavigation()}
        value={url}
        textColor="primary"
        indicatorColor="primary"
        backgroundColor={(theme) => theme.palette.background.default}
        centered
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Component baseUrl={url} extension={extension} {...props} />
        </ErrorBoundary>
      </TabRoutes>
    );
  };

  return (
    <Box bgcolor="action.hover">
      <Container maxWidth="md">
        <Box p={3} pb={4}>
          <Header Icon={ExtensionIcon}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography variant="h5" component="h1">
                <Link to={`/app/orgs/${extension.org_name}/extensions`}>
                  {extension.org_name}
                </Link>
              </Typography>
              <Typography variant="h4" component="h1" color="textPrimary">
                {extension.name}
              </Typography>
            </Breadcrumbs>
            <Typography variant="subtitle2" component="div">
              <List
                direction="horizontal"
                items={[
                  {
                    icon: extension.marketplace ? (
                      <CheckIcon />
                    ) : (
                      <NotInterestedIcon />
                    ),
                    text: "marketplace",
                  },
                  { text: extension.id, icon: <IdIcon /> },
                ]}
              />
            </Typography>
            <Typography variant="h6" component="h3">
              {extension.description}
            </Typography>
          </Header>
        </Box>
      </Container>
      <Routes>
        {app.routes.map(({ url, Component }: RouteComponent) => (
          <Route
            path={`${url}/*`}
            key={url}
            element={<Tabs Component={Component} url={app.getUrl(url)} />}
          />
        ))}
      </Routes>
    </Box>
  );
};

export default OrgExtensionHome;
