import { useStores } from "@metaweb/stores";
import GitHubIcon from "@mui/icons-material/GitHub";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import React from "react";
import { useAsync } from "react-use";

const Icons: any = {
  github: <GitHubIcon />,
};

const Integrations = () => {
  const { metablock } = useStores();

  const result = useAsync(async () => {
    const response = await metablock.get("/.api/integration/urls");
    return response.data;
  });
  const urls = result.value || [];

  return (
    <>
      {urls.map((d: any) => (
        <Card key={d.name}>
          <CardHeader
            title={
              <Typography color="error" variant="h5">
                Connect with {d.name}
              </Typography>
            }
          />
          <CardContent>
            <Button
              variant="contained"
              color="primary"
              startIcon={Icons[d.name]}
              href={d.url}
            >
              Connect your account
            </Button>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default Integrations;
