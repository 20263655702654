import { Plugin } from "@metablock/core";
import { CrudForm, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";
import { Navigate } from "react-router-dom";
import DangerZone from "../Components/DangerZone";

const PluginUpdate = ({ plugin }: { plugin: Plugin }) => {
  const { metablock, metaStore } = useStores();

  const submit = async (data: any) => {
    await metablock.plugins.update(plugin.id, data);
    metaStore.updatePluginVersion();
  };

  const title = `Update Plugin ${plugin.name}`;

  return (
    <Title title={title}>
      <CrudForm
        defaults={plugin}
        maxWidth={false}
        schema={metablock.schemaLoader("PluginUpdate")}
        submit={submit}
      />
      <PluginActions plugin={plugin} />
    </Title>
  );
};

const PluginActions = ({ plugin }: { plugin: Plugin }) => {
  const { messageStore, metablock } = useStores();
  const [deleted, setDeleted] = React.useState(false);

  if (deleted) {
    messageStore.success(`Successfully deleted block ${plugin.name}`);
    return <Navigate to="/app/plugins" />;
  }

  const deletePlugin = async () => {
    try {
      await metablock.plugins.delete(plugin.id);
      setDeleted(true);
    } catch (errors) {
      messageStore.error("Could not delete block");
    }
  };

  return (
    <DangerZone
      sx={{ mt: 3 }}
      items={[{ text: "Delete Plugin", action: deletePlugin }]}
    />
  );
};

export default PluginUpdate;
