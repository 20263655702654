import { NoSsr } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAsync } from "react-use";
import Private from "./Private/Index";
import Public from "./Public/Index";

const App = () => {
  return (
    <NoSsr>
      <Private />
    </NoSsr>
  );
};

const Main = () => {
  const { commonStore, userStore } = useStores();

  useAsync(async () => {
    if (commonStore.token) await userStore.getUser();
    commonStore.setAppLoaded();
  });

  if (commonStore.appLoaded) {
    return (
      <Routes>
        <Route path="/app/*" element={<App />} />
        <Route path="/*" element={<Public />} />
      </Routes>
    );
  } else {
    return null;
  }
};

export default Main;
