import { Block, BlockPlugin } from "@metablock/core";
import { ApiDataGrid, Crumb, RouteComponent, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import { gridStyle } from "@metaweb/theme";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { ExtensionApp, useMetablockApp } from "../../Extensions";
import columnDeleteEntry from "../Views/columnDeleteEntry";
import Plugin from "./Plugin";
import PluginAdd, { pluginColumns } from "./PluginAdd";

const PluginsHome = ({ block, cfg }: { block: Block; cfg: any }) => {
  const app = useMetablockApp({
    baseUrl: `/app/blocks/${block.space.name}/${block.name}/plugins`,
    routes: [
      {
        label: "plugins",
        url: "",
        Component: Plugins,
      },
      {
        label: "plugin",
        url: "/:plugin_name",
        Component: Plugin,
      },
    ],
  });
  const tabs: Crumb[] = [
    {
      text: "plugins",
      to: app.baseUrl,
    },
  ];

  return (
    <Routes>
      {app.routes.map(({ url, Component }: RouteComponent) => (
        <Route
          path={url}
          key={url}
          element={<Component tabs={tabs} app={app} block={block} cfg={cfg} />}
        />
      ))}
    </Routes>
  );
};

const Plugins = ({
  app,
  tabs,
  block,
  cfg,
}: {
  app: ExtensionApp;
  tabs: Crumb[];
  block: Block;
  cfg: any;
}) => {
  const { metablock } = useStores();
  const theme = useTheme();
  const api = metablock.blocks.pluginsLoader(block.id);

  const removePlugin = async (plugin: BlockPlugin) => {
    await metablock.blocks.deletePlugin(block.id, plugin.name);
  };

  const columns = React.useMemo(
    () =>
      pluginColumns(
        app,
        columnDeleteEntry(
          removePlugin,
          (plugin: BlockPlugin) =>
            `Remove ${plugin.name} plugin from ${block.url}`
        )
      ),
    [app.baseUrl]
  );

  return (
    <Title title={`${block.full_name} plugins`} tabs={tabs}>
      <Box>
        <ApiDataGrid api={api} columns={columns} {...gridStyle(theme)} />
      </Box>
      <Box mt={3}>
        <Typography variant="h6" align="center" pb={2}>
          Available to Install
        </Typography>
        <PluginAdd app={app} cfg={cfg} block={block} />
      </Box>
    </Title>
  );
};

export default PluginsHome;
