import { Plugin } from "@metablock/core";
import { ApiDataGrid, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";
import { booleanFormatter } from "../../Components/tableFormatters";

const PluginAnalytics = ({ plugin }: { plugin: Plugin }) => {
  const { metablock } = useStores();
  const api = metablock.plugins.blocksLoader(plugin.id);

  const columns = React.useMemo(
    () => [
      {
        key: "service_id",
        name: "Block ID",
      },
      {
        key: "active",
        name: "Enabled",
        formatter: booleanFormatter("active"),
      },
    ],
    []
  );

  const title = `Blocks using Plugin ${plugin.name}`;

  return (
    <Title title={title}>
      <ApiDataGrid columns={columns} api={api} />
    </Title>
  );
};

export default PluginAnalytics;
