import { Page, Unsplash } from "@metablock/react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { darkTheme } from "../../theme";
import Header from "../Components/Header";
import { title } from "../styles";
import JoinSection from "./JoinSection";
import ProductSection from "./ProductSection";

const Home: React.FC = () => {
  return (
    <Page>
      <Header
        light={false}
        changeColorOnScroll={{
          height: 400,
          backgroundColor: "neutral",
        }}
      />
      <Unsplash
        filter={0.8}
        photoId="hgxAdNZ_ccg"
        credit={false}
        minScrollWidth={0}
        speed={1}
      >
        <ThemeProvider theme={darkTheme}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography color="textPrimary" variant="h1" sx={title}>
                  Metablock
                </Typography>
                <Typography color="textPrimary" variant="h3" sx={title}>
                  Build your cloud infrastructure one block at a time
                </Typography>
                <Typography color="textPrimary" variant="h5">
                  Start small with a server-side rendered website and add new
                  features as your application grows
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
      </Unsplash>
      <ProductSection />
      <JoinSection />
    </Page>
  );
};

export default Home;
