import { Extension, Plugin } from "@metablock/core";
import {
  AppForm,
  CrudForm,
  Crumb,
  List,
  ScrollableList,
  Title,
} from "@metablock/react";
import { useStores } from "@metaweb/stores";
import CheckIcon from "@mui/icons-material/Check";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Typography from "@mui/material/Typography";
import React from "react";
import { Route, Routes } from "react-router-dom";

const PluginsHome = ({
  baseUrl,
  extension,
}: {
  baseUrl: string;
  extension: Extension;
}) => {
  const tabs: Crumb[] = [
    {
      text: "all plugins",
      to: baseUrl,
    },
    {
      text: "new plugin",
      to: `${baseUrl}/new`,
    },
  ];
  return (
    <Routes>
      <Route path="" element={<Plugins tabs={tabs} extension={extension} />} />
      <Route
        path="new"
        element={<NewPlugin tabs={tabs} extension={extension} />}
      />
    </Routes>
  );
};

export default PluginsHome;

const Plugins = ({
  tabs,
  extension,
}: {
  tabs: Crumb[];
  extension: Extension;
}) => {
  const { metablock } = useStores();
  const api = metablock.plugins.loader();
  api.reset({ extension_id: extension.id });
  const pluginEntry = (plugin: Plugin) => ({
    title: plugin.name,
    url: `/app/plugins/${plugin.id}`,
    children: <Description {...plugin} />,
  });
  return (
    <Title title={`${extension.name} plugins`} tabs={tabs}>
      <ScrollableList api={api} listEntry={pluginEntry} />
    </Title>
  );
};

const Description = (props: any) => {
  const { description, marketplace } = props;
  return (
    <>
      <Typography component="div">{description}</Typography>
      <Typography component="div">
        <List
          direction="horizontal"
          items={[
            {
              icon: marketplace ? <CheckIcon /> : <NotInterestedIcon />,
              text: "marketplace",
            },
          ]}
        />
      </Typography>
    </>
  );
};

const NewPlugin = ({
  tabs,
  extension,
}: {
  tabs: Crumb[];
  extension: Extension;
}) => {
  const { metablock } = useStores();

  const submit = async (data: any) => {
    return await metablock.extensions.createPlugin(extension, data);
  };

  const skipExtensionId = (name: string, props: any) => {
    if (name === "extension_id") return;
    return props;
  };

  return (
    <Title
      title={`Add a new plugin to ${extension.name} extension`}
      tabs={tabs}
    >
      <AppForm>
        <CrudForm
          maxWidth={false}
          schema={metablock.schemaLoader("PluginAdd")}
          fieldCallback={skipExtensionId}
          submit={submit}
        />
      </AppForm>
    </Title>
  );
};
