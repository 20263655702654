import { Extension, Org } from "@metablock/core";
import { Crumb, NotFound, Title } from "@metablock/react";
import Notebook from "@metaweb/Components/Notebook";
import { useStores } from "@metaweb/stores";
import React from "react";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";

const OrgExtension = ({ tabs }: { org: Org; tabs: Crumb[] }) => {
  const { metablock } = useStores();
  const { extensionId } = useParams() as { extensionId: string };
  const { loading, value } = useAsync(async () => {
    return await metablock.extensions.get(extensionId);
  }, [extensionId]);
  if (loading) return null;
  if (!value) return <NotFound />;
  const extension = value as Extension;

  const title = `Extension ${extension.name}`;
  const body = extension.docs || "No docs found";

  return (
    <Title title={title} tabs={tabs}>
      <Notebook body={body} />
    </Title>
  );
};

export default OrgExtension;
