import { Block, BlockPlugin, Plugin } from "@metablock/core";
import { CrudForm, Crumb, Link, NotFound, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import { Typography } from "@mui/material";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { ExtensionApp } from "../../Extensions";
import ErrorFallback from "../Components/ErrorFallback";

const Plugin = ({
  tabs,
  block,
  cfg,
}: {
  app: ExtensionApp;
  tabs: Crumb[];
  block: Block;
  cfg: any;
}) => {
  const { metablock } = useStores();
  const { plugin_name } = useParams() as { plugin_name: string };

  const { loading, value } = useAsync(async () => {
    try {
      const plugin = await metablock.plugins.get(plugin_name);
      const result = await metablock.blocks.getPlugins(block.id, {
        name: plugin_name,
      });
      const blockPlugin = result.data.length ? result.data[0] : null;
      return { plugin, blockPlugin };
    } catch (err) {
      return null;
    }
  }, [plugin_name]);

  if (loading) return null;
  if (!value) return <NotFound />;
  const { plugin, blockPlugin } = value;
  const title = <Link to={`/app/plugins/${plugin_name}`}>{plugin_name}</Link>;

  return (
    <Title
      pageTitle={`${plugin_name} for ${block.url}`}
      title={title}
      tabs={tabs}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <PluginForm block={block} plugin={plugin} blockPlugin={blockPlugin} />
      </ErrorBoundary>
    </Title>
  );
};

const PluginForm = ({
  block,
  plugin,
  blockPlugin,
}: {
  block: Block;
  plugin: Plugin;
  blockPlugin: any;
}) => {
  let defaults = blockPlugin || {};
  const { metablock, metaStore, messageStore } = useStores();
  const schemaLoader = metablock.schemaLoader("ServicePluginAdd");
  const getSchema = async () => {
    const formSchema = await schemaLoader();
    formSchema.properties.config = plugin.schema;
    delete formSchema.properties.name;
    return formSchema;
  };

  const submit = async (data: Record<string, any>) => {
    if (Object.keys(data).length > 0) {
      const { config, ...extra } = data;
      const body: any = { name: plugin.name, ...extra };
      body.config = { ...defaults.config, ...config };
      defaults = await metablock.blocks.updatePlugin(block.id, body);
      metaStore.updatePluginVersion();
    }
  };

  return (
    <>
      <Typography variant="body1" paragraph align="center">
        {plugin.description}
      </Typography>
      <CrudForm
        defaults={blockPlugin}
        schema={getSchema}
        submit={submit}
        onSuccess={(p: BlockPlugin) =>
          messageStore.success(`${plugin.name} added`)
        }
        onError={(error: string) =>
          messageStore.error(`Could not save ${plugin.name}: ${error}`)
        }
      />
    </>
  );
};

export default Plugin;
