import { Extension } from "@metablock/core";
import { ApiDataGrid, Crumb, NotFound, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import { gridSmall } from "@metaweb/theme";
import { Box, Button, Link, useTheme } from "@mui/material";
import React from "react";
import ReactJson from "react-json-view";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import DLFactory from "../../Components/DescriptionList";
import { linkFormatter } from "../../Components/tableFormatters";
import { formatDateTime, rowDate } from "../../utils/dates";

const Row = DLFactory({ sm: 3 });

const DeliveriesHome = ({
  baseUrl,
  extension,
}: {
  baseUrl: string;
  extension: Extension;
}) => {
  const tabs: Crumb[] = [
    {
      text: "All deliveries",
      to: baseUrl,
    },
  ];
  return (
    <Routes>
      <Route
        path=""
        element={
          <Deliveries tabs={tabs} extension={extension} baseUrl={baseUrl} />
        }
      />
      <Route
        path=":deliveryId"
        element={<Delivery tabs={tabs} extension={extension} />}
      />
    </Routes>
  );
};

export default DeliveriesHome;

const Deliveries = ({
  tabs,
  extension,
  baseUrl,
}: {
  tabs: Crumb[];
  extension: Extension;
  baseUrl: string;
}) => {
  const [delivering, setDelivering] = React.useState(false);
  const { metablock } = useStores();
  const api = metablock.extensions.deliveryLoader(extension.id);
  const theme = useTheme();
  const columns = React.useMemo(
    () => [
      {
        key: "id",
        name: "Delivery ID",
        formatter: linkFormatter("id", baseUrl),
      },
      {
        key: "event",
        name: "Event",
      },
      {
        key: "status",
        name: "Status",
      },
      {
        key: "created",
        name: "Delivered",
        formatter: rowDate("created"),
      },
      {
        key: "duration",
        name: "Duration",
      },
    ],
    []
  );
  const testDelivery = async () => {
    setDelivering(true);
    await metablock.extensions.testDelivery(extension.id, {
      message: "test from metablock.io",
    });
    setDelivering(false);
  };
  return (
    <Title title={`${extension.name} Webhook deliveries`} tabs={tabs}>
      <Box mb={1}>
        <Button
          onClick={testDelivery}
          variant="contained"
          disabled={delivering}
        >
          Test Delivery
        </Button>
      </Box>
      <ApiDataGrid api={api} columns={columns} {...gridSmall(theme)} />
    </Title>
  );
};

const Delivery = ({
  tabs,
  extension,
}: {
  tabs: Crumb[];
  extension: Extension;
}) => {
  const { metablock } = useStores();
  const { deliveryId } = useParams() as { deliveryId: string };

  const { loading, value } = useAsync(async () => {
    return await metablock.extensions.getDelivery(extension.id, deliveryId);
  });

  if (loading) return null;
  if (!value) return <NotFound />;
  const { id, event, url, duration, created, space_id, request, response } =
    value;

  return (
    <Title title={`${extension.name} Webhook delivery`} tabs={tabs}>
      <Box sx={{ mb: 2 }}>
        <Row entry="Delivery ID">{id}</Row>
        <Row entry="Event">{event}</Row>
        <Row entry="Delivery url">
          <Link href={url}>{url}</Link>
        </Row>
        <Row entry="Delivered At">{formatDateTime(new Date(created))}</Row>
        <Row entry="Duration">{duration} seconds</Row>
        <Row entry="Space ID">{space_id}</Row>
      </Box>
      <ReactJson src={{ request, response }} theme="tomorrow" />
    </Title>
  );
};
