import { Link } from "@metablock/react";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import React from "react";
import { NoIcon, YesIcon } from "./Icons";

export const booleanFormatter = (code: string) => {
  return ({ row }: any) => {
    const value = row[code];
    return <IconButton>{value ? <YesIcon /> : <NoIcon />}</IconButton>;
  };
};

export const linkFormatter = (code: string, baseUrl?: string) => {
  return ({ row }: any) => {
    const value = row[code] || "";
    return baseUrl === undefined ? (
      <MuiLink href={value} target="_blank">
        {value}
      </MuiLink>
    ) : (
      <Link to={`${baseUrl}/${value}`}>{value}</Link>
    );
  };
};
