import { Section } from "@metablock/react";
import Typography from "@mui/material/Typography";
import React from "react";

const JoinSection: React.FC = () => {
  return (
    <Section>
      <Typography variant="h3" align="center" pt={10} pb={10}>
        Coming soon!
      </Typography>
    </Section>
  );
};

export default JoinSection;
