import Grid from "@mui/material/Grid";
import React from "react";

const DLFactory = (options?: any) => {
  const { sm = 4, pb = 1 } = options || {};

  return (props: any) => {
    const { entry, children } = props;
    return (
      <Grid container spacing={0} sx={{ pb }}>
        <Grid item xs={12} sm={sm}>
          <strong>{entry}</strong>
        </Grid>
        <Grid item xs={12} sm="auto">
          {children}
        </Grid>
      </Grid>
    );
  };
};

export default DLFactory;
