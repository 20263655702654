import { Link, NotFound, Page, RouteComponent } from "@metablock/react";
import BlockSvg from "@metaweb/icons/block.svg";
import { useStores } from "@metaweb/stores";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { useMetablockApp } from "../../Extensions";
import Header from "../Components/Header";
import List from "../Components/List";
import TabRoutes from "../Components/TabRoutes";
import { IdIcon, LinkIcon } from "../Icons";
import Assets from "./Assets";
import Deployments from "./Deployments";
import Main from "./Main";
import Plugins from "./Plugins";
import Routing from "./Routing";

const BlockIcon = (props: any) => {
  const { width, ...extra } = props;
  return (
    <BlockSvg width={width} height={width} viewBox="0 0 32 32" {...extra} />
  );
};

const BlockHome = (props: any) => {
  const { space_name, block_name } = useParams() as {
    space_name: string;
    block_name: string;
  };
  const { metablock } = useStores();
  const app = useMetablockApp({
    baseUrl: `/app/blocks/${space_name}/${block_name}`,
    routes: [
      {
        label: "settings",
        url: "",
        Component: Main,
      },
      {
        label: "Plugins",
        url: "plugins",
        routePath: "plugins/*",
        Component: Plugins,
      },
      {
        label: "assets",
        url: "assets",
        Component: Assets,
      },
      {
        label: "routing",
        url: "routing",
        Component: Routing,
      },
      {
        label: "deployments",
        url: "deployments",
        Component: Deployments,
      },
    ],
  });
  const { loading, value } = useAsync(async () => {
    const blocks = await metablock.spaces.getBlocks(space_name, {
      name: block_name,
    });
    if (blocks.data.length) {
      const block = blocks.data[0];
      const orgId = block.space.org_id || block.space.name;
      const [cfg, org] = await Promise.all([
        metablock.blocks.config(block.id),
        metablock.orgs.get(orgId),
      ]);
      return { block, cfg, org };
    }
  }, [space_name, block_name]);

  if (loading) return null;
  if (!value) return <NotFound />;
  const { block, cfg, org } = value;
  const { space } = block;
  const blockDomain = new URL(block.url).hostname;

  const Tabs = (opts: any) => {
    const { Component, options, url, ...props } = opts;
    return (
      <TabRoutes
        routes={app.getNavigation()}
        value={url}
        textColor="primary"
        indicatorColor="primary"
        sx={{ backgroundColor: "palette.background.default" }}
        centered
        {...options}
      >
        <Component block={block} cfg={cfg} {...props} />
      </TabRoutes>
    );
  };

  return (
    <Page title={`${blockDomain} block`}>
      <Box bgcolor="action.hover">
        <Container maxWidth="md">
          <Box p={3} pb={4}>
            <Header Icon={BlockIcon}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography variant="h6" component="p">
                  <Link to={`/app/orgs/${org.short_name}`}>
                    {org.short_name}
                  </Link>
                </Typography>
                <Typography variant="h5" component="p">
                  <Link to={`/app/spaces/${space.name}`}>{space.name}</Link>
                </Typography>
                <Typography variant="h4" component="p" color="textPrimary">
                  {blockDomain}
                </Typography>
              </Breadcrumbs>
              <Typography variant="subtitle2" component="div">
                <List
                  direction="horizontal"
                  items={[
                    { href: block.url, icon: <LinkIcon /> },
                    { text: block.id, icon: <IdIcon /> },
                  ]}
                />
              </Typography>
            </Header>
          </Box>
        </Container>
        <Routes>
          {app.routes.map(({ url, routePath, Component }: RouteComponent) => (
            <Route
              path={routePath || url}
              key={url}
              element={<Tabs Component={Component} url={app.getUrl(url)} />}
            />
          ))}
          <Route element={<NotFound />} />
        </Routes>
      </Box>
    </Page>
  );
};

export default BlockHome;
