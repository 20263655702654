import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";

const sx = {
  root: {
    display: "flex",
    backgroundColor: "transparent",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  cover: {
    width: 151,
    color: "primary.dark",
  },
  svg: {
    fill: "primary.dark",
  },
};

const Header = (props: any) => {
  const { Icon, children } = props;

  return (
    <Card sx={sx.root} elevation={0} square>
      <Box sx={sx.cover}>
        <Icon width={150} sx={sx.svg} />
      </Box>
      <Box sx={sx.details}>
        <CardContent>{children}</CardContent>
      </Box>
    </Card>
  );
};

export default Header;
