import { Section } from "@metablock/react";
import CloudDoneTwoToneIcon from "@mui/icons-material/CloudDoneTwoTone";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import DirectionsBoatTwoToneIcon from "@mui/icons-material/DirectionsBoatTwoTone";
import DnsTwoToneIcon from "@mui/icons-material/DnsTwoTone";
import DomainIcon from "@mui/icons-material/Domain";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import IconCard from "../Components/Card";

const ProductSection: React.FC = () => {
  return (
    <Section>
      <Container>
        <Box mt={3} mb={5}>
          <Typography align="center" variant="h5" component="h3">
            Ship Great Applications Effortlessly with all the Features You Need
          </Typography>
        </Box>
      </Container>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <IconCard icon={<DirectionsBoatTwoToneIcon fontSize="large" />}>
            Develop and ship without any in-house infrastructure
          </IconCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <IconCard icon={<DeveloperModeIcon fontSize="large" />}>
            It works with any front-end or back-end technology, React, Vue,
            Node, Python
          </IconCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <IconCard icon={<CloudDoneTwoToneIcon fontSize="large" />}>
            Staging previews at every push, to production with a simple click
          </IconCard>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <IconCard icon={<VerifiedUserOutlinedIcon fontSize="large" />}>
            SSL out of the box for every app. No tedious renewals
          </IconCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <IconCard icon={<DomainIcon fontSize="large" />}>
            Custom domains and DNS management
          </IconCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <IconCard icon={<DnsTwoToneIcon fontSize="large" />}>
            Serverless functions in the language you prefer
          </IconCard>
        </Grid>
      </Grid>
    </Section>
  );
};

export default ProductSection;
