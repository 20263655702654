import { Crumb, ListItemLink } from "@metablock/react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import React from "react";
import { drawerWidth } from "../theme";
import { ChevronLeftIcon, ChevronRightIcon, MenuIcon } from "./Icons";
import RightBox from "./RightBox";

export interface MetaDrawerProps extends DrawerProps {
  listItems: Array<Array<Crumb>>;
  left?: React.ReactNode;
  rightLinks?: React.ReactNode;
  open?: boolean;
  drawerHeader?: React.ReactNode;
}

const ContentDrawerClosed = styled("main")(({ theme }: any) => ({
  flexGrow: 1,
  height: "100vh",
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
}));

const ContentDrawerOpen = styled("main")(({ theme }: any) => ({
  flexGrow: 1,
  height: "100vh",
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: drawerWidth,
}));

const sxHeader = (theme: any, extra: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  ...extra,
});

const PersistentDrawerLeft = (props: MetaDrawerProps) => {
  const { listItems, rightLinks, left, drawerHeader = <Box /> } = props;
  const [open, setOpen] = React.useState(props.open || false);
  const theme = useTheme();
  let sxAppBar: Record<string, any> = {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  if (open)
    sxAppBar = {
      ...sxAppBar,
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    };

  const Content = open ? ContentDrawerOpen : ContentDrawerClosed;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <AppBar position="fixed" sx={sxAppBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            size="large"
            sx={{
              marginRight: theme.spacing(2),
              display: open ? "none" : null,
            }}
          >
            <MenuIcon />
          </IconButton>
          {left}
          <RightBox>{rightLinks}</RightBox>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Box sx={sxHeader(theme, { justifyContent: "space-between" })}>
          {drawerHeader}
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </Box>
        {listItems.map((group, index) => (
          <React.Fragment key={`group-${index}`}>
            <Divider />
            <List>
              {group.map((entry: Crumb) => (
                <ListItemLink button key={entry.text} to={entry.to}>
                  {entry.icon ? (
                    <ListItemIcon>{entry.icon}</ListItemIcon>
                  ) : null}
                  <ListItemText primary={entry.text} />
                </ListItemLink>
              ))}
            </List>
          </React.Fragment>
        ))}
      </Drawer>
      <Content>
        <Toolbar />
        {props.children}
      </Content>
    </Box>
  );
};

export default PersistentDrawerLeft;
