import loadable from "@loadable/component";
import { SpaceExtension } from "@metablock/core";
import { Crumb, RouteComponent } from "@metablock/react";
import React from "react";
import Account from "./Account";
import Orgs from "./Orgs";

const metablockExtensions: Record<string, any> = {
  account: Account,
  orgs: Orgs,
};

interface ExtensionAppInput {
  baseUrl: string;
  routes?: RouteComponent[];
  extensions?: Record<string, any>;
}

class ExtensionApp {
  baseUrl: string;
  extensions: Record<string, any>;
  routes: RouteComponent[];
  private rendered = false;

  constructor({ baseUrl, routes, extensions }: ExtensionAppInput) {
    this.baseUrl = baseUrl;
    this.extensions = {};
    this.routes = routes || [];
    const ext = extensions || {};
    Object.keys(ext).forEach((name: string) => {
      this.register(name, ext[name]);
    });
  }

  getUrl(path: string): string {
    return path ? `${this.baseUrl}/${path}` : this.baseUrl;
  }

  getNavigation(): Crumb[] {
    return this.routes.map((nav: RouteComponent) => ({
      // TODO: unify this
      text: nav.label,
      label: nav.label,
      url: this.getUrl(nav.url),
      to: this.getUrl(nav.url),
    }));
  }

  register(name: string, extension: any) {
    this.extensions[name] = extension;
  }

  extensionComponent(ext: SpaceExtension): RouteComponent {
    const script = ext.script || "";
    const ExtensionComponent: any = script
      ? loadable(() => import(/* webpackIgnore: true */ script))
      : metablockExtensions[ext.name];
    const baseUrl = `${this.baseUrl}/${ext.name}`;

    return {
      label: ext.name,
      url: ext.name,
      Component: (props: any) => {
        return (
          <ExtensionComponent {...props} extension={ext} baseUrl={baseUrl} />
        );
      },
    };
  }
}

const useMetablockApp = (opts: ExtensionAppInput): ExtensionApp => {
  return new ExtensionApp(opts);
};

export { ExtensionApp, ExtensionAppInput, useMetablockApp };
