import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import dom from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Main from "./Main";
import { lightTheme } from "./theme";

const root = document.getElementById("__metablock") as HTMLElement;

const App: React.FC = () => (
  <BrowserRouter>
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Main />
    </ThemeProvider>
  </BrowserRouter>
);

dom.hydrateRoot(root, <App />);
