import { TextField, useForm } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Integrations from "./views/Integrations";

const Home: React.FC = () => {
  const { userStore } = useStores();
  const user = userStore.current;
  const form = useForm({
    defaultValues: user,
    handleSubmit: async (
      data: Record<string, any>,
      dirty: Record<string, any>
    ) => {
      if (Object.keys(dirty).length) {
        await userStore.updateUser(dirty);
        const errors = userStore.errors;
        if (errors && errors.status === 422) {
          form.setErrors(errors.errors, true);
        }
      }
    },
  });
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={6}>
        <form onSubmit={form.onSubmit()} noValidate>
          <TextField
            form={form}
            fullWidth
            required
            name="first_name"
            margin="normal"
            variant="outlined"
            label="First name"
          />
          <TextField
            form={form}
            fullWidth
            name="last_name"
            margin="normal"
            label="Last name"
            variant="outlined"
          />
          <FormControl margin="normal">
            <Button type="submit" variant="contained" color="inherit">
              Update
            </Button>
          </FormControl>
        </form>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Integrations />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box borderColor="error.main" color="error.main" border={1}>
          <Card>
            <CardHeader
              title={
                <Typography color="error" variant="h5">
                  Danger Zone
                </Typography>
              }
            />
            <CardContent>Danger</CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Home;
