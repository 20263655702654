import { Org, Space } from "@metablock/core";
import { Crumb, Link, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import BusinessIcon from "@mui/icons-material/Business";
import CheckIcon from "@mui/icons-material/Check";
import LanguageIcon from "@mui/icons-material/Language";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiList from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAsync } from "react-use";
import List from "../Components/List";
import SpaceAdd from "./SpaceAdd";

interface SpaceListProps {
  baseUrl: string;
  onSelect?: (space: Space) => void;
}

interface SpaceProps {
  space: Space;
}

const OrgSpacesHome = ({ onSelect, baseUrl }: SpaceListProps) => {
  const { orgStore } = useStores();
  const org = orgStore.currentOrg as Org;

  const tabs: Crumb[] = [
    {
      text: "all spaces",
      to: baseUrl,
    },
    {
      text: "new space",
      to: `${baseUrl}/new`,
    },
  ];

  return (
    <Routes>
      <Route
        path=""
        element={<SpaceList tabs={tabs} org={org} onSelect={onSelect} />}
      />
      <Route path="/new" element={<SpaceAdd tabs={tabs} org={org} />} />
    </Routes>
  );
};

const SpaceList = (props: any) => {
  const { onSelect, org, tabs } = props;
  const { spaceStore } = useStores();

  const spaces: Space[] = spaceStore.spaces || [];
  const result = useAsync(async () => {
    return await spaceStore.getList();
  }, [org.id]);
  if (result.loading) return null;

  const selectSpace = (space: Space) => {
    spaceStore.setSpace({ ...space });
    if (onSelect) onSelect(space);
  };

  return (
    <Title title={`${org?.short_name} spaces`} tabs={tabs}>
      <MuiList component="nav">
        {spaces.map((space: Space) => (
          <React.Fragment key={space.id}>
            <ListItem
              button
              selected={spaceStore.space && spaceStore.space.id === space.id}
              alignItems="flex-start"
              onClick={() => selectSpace(space)}
            >
              <SpaceEntry space={space} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </MuiList>
    </Title>
  );
};

const SpaceEntry = (props: SpaceProps) => {
  const { space } = props;
  return (
    <Box bgcolor="transparent" pt={2} pb={2}>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        <Link to={`/app/spaces/${space.name}`}>{space.name}</Link>
      </Typography>
      <Typography component="div">
        <List
          direction="horizontal"
          items={[
            {
              icon: space.hosted ? <CheckIcon /> : <NotInterestedIcon />,
              text: "metablock hosted",
            },
            { icon: <LanguageIcon />, href: space.domain },
            {
              icon: <BusinessIcon />,
              to: `/orgs/${space.org_name}`,
              text: space.org_name,
            },
          ]}
        />
      </Typography>
    </Box>
  );
};

export default OrgSpacesHome;
