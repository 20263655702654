import React from "react";
import { Route, Routes } from "react-router-dom";
import PluginHome from "./Plugin";
import Plugins from "./Plugins";

const PluginsHome = () => {
  return (
    <Routes>
      <Route path="" element={<Plugins />} />
      <Route path="/:pluginId/*" element={<PluginHome />} />
    </Routes>
  );
};

export default PluginsHome;
