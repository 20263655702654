import { UserOrg } from "@metablock/core";
import { NotFound } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React, { useEffect } from "react";
import "react-data-grid/lib/styles.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAsync } from "react-use";
import Drawer from "../Components/Drawer";
import { OrgIcon } from "../Components/Icons";
import AccountHome from "./Account";
import BlockHome from "./Block";
import UserMenu from "./Components/UserMenu";
import OrgExtensionHome from "./Extension";
import Messages from "./Messages";
import OrgHome from "./Org";
import Plugins from "./Plugins";
import SpaceHome from "./Space";
import nav from "./sideNav";

const MainApp = () => {
  const { userStore, orgStore, spaceStore } = useStores();

  const { loading } = useAsync(async () => {
    if (userStore.current)
      await Promise.all([orgStore.getOrgs(), spaceStore.getGenesis()]);
  });
  if (loading) return null;
  else if (!userStore.current) return <Navigate to="/" />;
  else if (orgStore.orgs && spaceStore.genesisSpace) return <AppRoutes />;
  else return null;
};

const AppRoutes = () => {
  const { orgStore, messageStore } = useStores();
  useEffect(() => messageStore.clear());
  const fullNav = nav(
    orgStore.orgs?.map((org: UserOrg) => ({
      text: org.org_name,
      icon: <OrgIcon />,
      to: `/app/orgs/${org.org_name}`,
    }))
  );
  return (
    <Drawer listItems={fullNav} rightLinks={<UserMenu />}>
      <Messages />
      <Routes>
        <Route path="/account/*" element={<AccountHome />} />
        <Route path="/plugins/*" element={<Plugins />} />
        <Route
          path="/blocks/:space_name/:block_name/*"
          element={<BlockHome />}
        />
        <Route path="/spaces/:space_name/*" element={<SpaceHome />} />
        <Route
          path="/extensions/:extensionId/*"
          element={<OrgExtensionHome />}
        />
        <Route path="/orgs/:org_name/*" element={<OrgHome />} />
        <Route path="/" element={<Navigate to="account" />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Drawer>
  );
};

export default MainApp;
