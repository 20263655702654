import { CheckBoxField, TextField, useForm } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import React from "react";

const BlockEdit = (props: any) => {
  const { messageStore, metablock } = useStores();
  const { block, sx } = props;

  const form = useForm({
    defaultValues: block,
    handleSubmit: async (
      data: Record<string, any>,
      dirty: Record<string, any>
    ) => {
      if (Object.keys(dirty).length) {
        try {
          const updated = await metablock.blocks.update(block.id, dirty);
          messageStore.success(
            `Successfully updated block ${updated.full_name}`
          );
        } catch (e: any) {
          if (e.status === 422) {
            form.setErrors(e.errors, true);
          }
        }
      }
    },
  });

  return (
    <Card sx={sx}>
      <CardHeader title="Edit Block" />
      <CardContent>
        <form onSubmit={form.onSubmit()} noValidate>
          <TextField
            form={form}
            fullWidth
            required
            name="name"
            label="Block name"
            variant="outlined"
          />
          <CheckBoxField
            form={form}
            fullWidth
            name="root"
            margin="normal"
            color="default"
            label="Serve the block as root domain"
          />
          <CheckBoxField
            form={form}
            fullWidth
            name="html"
            color="default"
            label="Block for serving HTML applications"
          />
          <CheckBoxField
            form={form}
            fullWidth
            name="use_cdn"
            color="default"
            label="Use CDN to proxy requests (faster content delivery)"
          />
          <FormControl margin="normal">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
};

export default BlockEdit;
