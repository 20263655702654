import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";

const Card = (props: any) => {
  const { children, title, titleVariant = "h4" } = props;
  return (
    <MuiCard>
      <CardContent>
        {title ? (
          <Typography variant={titleVariant} paragraph>
            {title}
          </Typography>
        ) : null}
        {children}
      </CardContent>
    </MuiCard>
  );
};

export default Card;
