import { ApiDataGrid } from "@metablock/react";
import columnDeleteEntry from "@metaweb/Private/Views/columnDeleteEntry";
import { useStores } from "@metaweb/stores";
import { gridSmall } from "@metaweb/theme";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { formatTimeAgo } from "../../utils/dates";

const Tokens = () => {
  const { metablock } = useStores();
  const theme = useTheme();
  const [tokenInfo, ShowNewToken] = useState<any>({});
  const api = metablock.user.tokensLoader();

  const createToken = async () => {
    const result = await metablock.user.createToken();
    ShowNewToken(result);
  };

  const handleClose = () => {
    ShowNewToken({});
  };
  const deleteToken = async (tokenId: string) => {
    await metablock.user.deleteToken(tokenId);
    ShowNewToken({});
  };
  const columns = React.useMemo(
    () => [
      { key: "id", name: "ID" },
      {
        key: "created",
        name: "Created",
        renderCell: ({ row }: any) => (
          <span>{formatTimeAgo(new Date(row.created))}</span>
        ),
      },
      {
        key: "last_access",
        name: "Last Used",
        renderCell: ({ row }: any) => (
          <span>
            {row.last_access
              ? formatTimeAgo(new Date(row.last_access))
              : "never"}
          </span>
        ),
      },
      {
        key: "access_count",
        name: "Used",
      },
      columnDeleteEntry(deleteToken, (row: any) => `Delete token ${row.id}`),
    ],
    []
  );

  return (
    <>
      <Box pb={2}>
        <Button color="primary" variant="contained" onClick={createToken}>
          Create new token
        </Button>
      </Box>
      <ApiDataGrid columns={columns} api={api} {...gridSmall(theme)} />
      <Dialog
        open={tokenInfo.key !== undefined}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          New API Token created
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{tokenInfo.key}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Tokens;
