import { Header as BaseHeader, Link } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";
import logos from "../../Components/logos";
import HeaderLinks from "./HeaderLinks";
import UserLinks from "./UserLinks";

const Header = (props: any) => {
  const { light = true, changeColorOnScroll } = props;
  const brandImage1 = light ? logos.dark : logos.light;
  const brandImage2 = light ? logos.light : logos.dark;
  const { userStore } = useStores();
  const RightLinks = userStore.current ? UserLinks : HeaderLinks;

  const BrandComponent = (props: any) => {
    const { colorChange } = props;
    return (
      <Link to="/">
        <img
          src={colorChange ? brandImage2 : brandImage1}
          alt="metablock"
          height={60}
        />
      </Link>
    );
  };
  return (
    <BaseHeader
      fixed
      backgroundColor="transparent"
      color="#fff"
      BrandComponent={BrandComponent}
      RightLinks={RightLinks}
      paddingTop={1}
      changeColorOnScroll={changeColorOnScroll}
    />
  );
};

export default Header;
