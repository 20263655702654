import { Block, DataApi } from "@metablock/core";
import { Crumb, List, ScrollableList, Title } from "@metablock/react";
import Typography from "@mui/material/Typography";
import React from "react";
import { HrefIcon, PersonIcon } from "../Icons";

const Blocks = ({
  tabs,
  title,
  api,
}: {
  tabs: Crumb[];
  title: string;
  api: DataApi<Block>;
}) => {
  const blockEntry = (block: Block) => ({
    title: block.full_name,
    url: `/app/blocks/${block.space.name}/${block.name}`,
    children: <Description block={block} />,
  });
  return (
    <Title title={title} tabs={tabs}>
      <ScrollableList api={api} listEntry={blockEntry} />
    </Title>
  );
};

const Description = ({ block }: { block: Block }) => {
  const items: Record<string, any>[] = [
    {
      icon: <HrefIcon />,
      text: block.url,
      href: block.url,
    },
  ];
  if (block.user_id) {
    items.push({
      icon: <PersonIcon />,
      text: block.user_id,
    });
  }
  return (
    <>
      <Typography component="div">{block.description}</Typography>
      <Typography component="div">
        <List direction="horizontal" items={items} />
      </Typography>
    </>
  );
};

export default Blocks;
