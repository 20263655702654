import { Space, SpaceExtension, User } from "@metablock/core";
import { Link, NotFound, RouteComponent } from "@metablock/react";
import SpaceSvg from "@metaweb/icons/shuttle.svg";
import { useStores } from "@metaweb/stores";
import IdIcon from "@mui/icons-material/DeviceHub";
import LinkIcon from "@mui/icons-material/Link";
import CdnIcon from "@mui/icons-material/Public";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { ExtensionApp, useMetablockApp } from "../../Extensions";
import ErrorFallback from "../Components/ErrorFallback";
import Header from "../Components/Header";
import List from "../Components/List";
import TabRoutes from "../Components/TabRoutes";
import Blocks from "./Blocks";
import Extensions from "./Extensions";
import SpaceActions from "./Settings";

const SpaceIcon = (props: any) => {
  const { width, ...extra } = props;
  return (
    <SpaceSvg width={width} height={width} viewBox="0 0 512 512" {...extra} />
  );
};

const SpaceHome = () => {
  const { space_name } = useParams() as { space_name: string };
  const { metablock, orgStore, userStore } = useStores();
  const user = userStore.current as User;
  const app = useMetablockApp({
    baseUrl: `/app/spaces/${space_name}`,
    routes: [
      {
        label: "settings",
        url: "settings",
        Component: SpaceActions,
      },
      {
        label: "blocks",
        url: "",
        Component: Blocks,
      },
      {
        label: "extensions",
        url: "extensions",
        Component: Extensions,
      },
    ],
  });

  const { loading, value } = useAsync(async () => {
    const space = await metablock.spaces.get(space_name);
    const [org, exts] = await Promise.all([
      orgStore.getOrg(space.org_id),
      await metablock.spaces.getExtensions(space_name),
    ]);
    return { org, space, extensions: exts?.data || [] };
  }, [space_name]);

  if (loading) return null;
  if (!value) return <NotFound />;
  const { space, extensions } = value;
  extensions.forEach((ext: SpaceExtension) => {
    app.routes.push(app.extensionComponent(ext));
  });
  return <SpaceApp space={space} app={app} extensions={extensions} />;
};

const SpaceApp = ({
  app,
  space,
  extensions,
}: {
  space: Space;
  app: ExtensionApp;
  extensions: SpaceExtension[];
}) => {
  const Tabs = (opts: any) => {
    const { Component, url, ...props } = opts;
    return (
      <TabRoutes
        routes={app.getNavigation()}
        value={url}
        textColor="primary"
        indicatorColor="primary"
        sx={{ backgroundColor: "palette.background.default" }}
        centered
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Component
            space={space}
            baseUrl={url}
            extensions={extensions}
            {...props}
          />
        </ErrorBoundary>
      </TabRoutes>
    );
  };

  return (
    <Box bgcolor="action.hover">
      <Container maxWidth="md">
        <Box p={3} pb={4}>
          <Header Icon={SpaceIcon}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography variant="h5" component="h1">
                <Link to={`/app/orgs/${space.org_name}`}>{space.org_name}</Link>
              </Typography>
              <Typography variant="h4" component="h1" color="textPrimary">
                {space.name}
              </Typography>
            </Breadcrumbs>
            <Typography variant="subtitle2" component="div">
              <List
                direction="vertical"
                items={[
                  { to: `https://${space.domain}`, icon: <LinkIcon /> },
                  { text: space.id, icon: <IdIcon /> },
                  { text: space.cdn, icon: <CdnIcon /> },
                ]}
              />
            </Typography>
          </Header>
        </Box>
      </Container>
      <Routes>
        {app.routes.map(({ url, Component }: RouteComponent) => (
          <Route
            path={`${url}/*`}
            key={url}
            element={<Tabs Component={Component} url={app.getUrl(url)} />}
          />
        ))}
      </Routes>
    </Box>
  );
};

export default SpaceHome;
