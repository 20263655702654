import { Plugin } from "@metablock/core";
import { Link, NotFound, Page, RouteComponent } from "@metablock/react";
import PluginSvg from "@metaweb/icons/plugin.svg";
import { useStores } from "@metaweb/stores";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { useMetablockApp } from "../../Extensions";
import ErrorFallback from "../Components/ErrorFallback";
import Header from "../Components/Header";
import TabRoutes from "../Components/TabRoutes";
import PluginAnalytics from "./PluginAnalytics";
import PluginUpdate from "./PluginUpdate";

const PluginIcon = (props: any) => {
  const { width } = props;
  return <PluginSvg width={width} height={width} viewBox="0 0 130 130" />;
};

const PluginHome: React.FC = (props: any) => {
  const { metablock, metaStore } = useStores();
  const { pluginId } = useParams() as { pluginId: string };

  const app = useMetablockApp({
    baseUrl: `/app/plugins/${pluginId}`,
    routes: [
      {
        label: "analytics",
        url: "",
        Component: PluginAnalytics,
      },
      {
        label: "settings",
        url: "settings",
        Component: PluginUpdate,
      },
    ],
  });

  const { loading, value } = useAsync(async () => {
    return await metablock.plugins.get(pluginId);
  }, [pluginId, metaStore.pluginVersion]);

  if (loading) return null;
  if (!value) return <NotFound />;
  const plugin = value as Plugin;

  const Tabs = (opts: any) => {
    const { Component, url, ...props } = opts;
    return (
      <TabRoutes
        routes={app.getNavigation()}
        value={url}
        textColor="primary"
        indicatorColor="primary"
        sx={{ backgroundColor: "palette.background.default" }}
        maxWidth="lg"
        centered
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Component baseUrl={url} {...props} />
        </ErrorBoundary>
      </TabRoutes>
    );
  };

  return (
    <Page title={value.name}>
      <Box sx={{ backgroundColor: "action.hover" }}>
        <Container maxWidth="md">
          <Box p={3}>
            <Header Icon={PluginIcon}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography variant="h5" component="h1">
                  <Link to="/app/plugins">plugins</Link>
                </Typography>
                <Typography variant="h4" component="h1" color="textPrimary">
                  {value.name}
                </Typography>
              </Breadcrumbs>
              <Typography variant="subtitle2" component="h3">
                created by{" "}
                <Link to={`/app/orgs/${value.org_name}`}>{value.org_name}</Link>
              </Typography>
              <Typography variant="h6" component="h3">
                {value.description}
              </Typography>
              <Typography>
                A plugin part of the{" "}
                <Link to={`/app/extensions/${plugin.extension_id}`}>
                  {plugin.extension_name}
                </Link>{" "}
                extension
              </Typography>
            </Header>
          </Box>
        </Container>
      </Box>
      <Routes>
        {app.routes.map(({ url, routePath, Component }: RouteComponent) => (
          <Route
            path={routePath || url}
            key={url}
            element={
              <Tabs
                Component={Component}
                plugin={plugin}
                url={app.getUrl(url)}
              />
            }
          />
        ))}
      </Routes>
    </Page>
  );
};

export default observer(PluginHome);
