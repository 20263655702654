import { CommonStore } from "@metablock/store";
import { action, makeObservable, observable } from "mobx";

class MetaStore {
  commonStore: CommonStore;
  updated = new Date();
  pluginVersion = 0;

  constructor(commonStore: CommonStore) {
    this.commonStore = commonStore;
    makeObservable(this, {
      updated: observable,
      pluginVersion: observable,
      update: action,
      updatePluginVersion: action,
    });
  }

  get cli() {
    return this.commonStore.cli;
  }

  update() {
    this.updated = new Date();
  }

  updatePluginVersion() {
    this.pluginVersion += 1;
  }
}

export default MetaStore;
