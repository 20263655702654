import { CmsRoute, ListItemLink } from "@metablock/react";
import List from "@mui/material/List";

import Drawer from "@metaweb/Components/Drawer";
import Footer from "@metaweb/Components/Footer";
import Notebook from "@metaweb/Components/Notebook";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import React from "react";

const DocsList = (props: any) => {
  const { data, ...extra } = props;
  const links = data.map((entry: any) => ({
    name: entry.title,
    to: entry.urlPath,
  }));
  const DrawerHeader = () => (
    <List>
      <ListItemLink button to="">
        Documentation
      </ListItemLink>
    </List>
  );
  return (
    <Drawer listItems={[links]} open={true} drawerHeader={<DrawerHeader />}>
      <Container maxWidth="lg">
        <Box component="main">
          <CmsRoute
            ListComponent={false}
            EntryComponent={Notebook}
            defaultSlug="default"
            {...extra}
          />
        </Box>
      </Container>
      <Footer />
    </Drawer>
  );
};

export default DocsList;
