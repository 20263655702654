import { DataApi, HttpComponent, Metablock, Org, User } from "@metablock/core";

class SpaceClient extends HttpComponent {
  spaceId: string;

  constructor(cli: Metablock, spaceId: string) {
    super(cli);
    this.spaceId = spaceId;
  }

  get url(): string {
    return `${this.cli.apiUrl}/spaces/${this.spaceId}`;
  }

  accountsLoader(): DataApi<User> {
    return this.cli.loader(`${this.url}/users`);
  }

  orgsLoader(): DataApi<Org> {
    return this.cli.loader(`${this.url}/orgs`);
  }

  async accountSettings(): Promise<any> {
    const response = await this.cli.get(`${this.url}/account`);
    return response.data;
  }
}

export default SpaceClient;
