import { Space } from "@metablock/core";
import { Crumb } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAsync } from "react-use";
import Blocks from "../Views/Blocks";
import NewBlock from "../Views/NewBlock";

const BlocksHome = ({ baseUrl }: { baseUrl: string }) => {
  const tabs: Crumb[] = [
    {
      text: "all blocks",
      to: baseUrl,
    },
    {
      text: "new block",
      to: `${baseUrl}/new`,
    },
  ];
  return (
    <Routes>
      <Route path="" element={<UserBlocks tabs={tabs} />} />
      <Route path="new" element={<NewUserBlock tabs={tabs} />} />
    </Routes>
  );
};

export default BlocksHome;

const UserBlocks = ({ tabs }: { tabs: Crumb[] }) => {
  const { metablock } = useStores();
  const api = metablock.user.blockLoader();

  return <Blocks title="User blocks" api={api} tabs={tabs} />;
};

const NewUserBlock = ({ tabs }: { tabs: Crumb[] }) => {
  const { metablock } = useStores();

  const { loading, value } = useAsync(async () => {
    const loader = metablock.spaces.loader({ users: true });
    await loader.loadData();
    return loader.data;
  });

  if (loading) return null;

  const submit = async (data: any) => {
    await metablock.user.createBlock(data);
  };

  const options = (value || []).map((space: Space) => ({
    label: space.domain,
    value: space.id,
  }));

  const fieldCallback = (
    name: string,
    props: Record<string, any>,
    schema: any
  ): Record<string, any> => {
    if (name === "space_id") {
      schema.description = "Block domain";
      return {
        ...props,
        options,
      };
    }
    return props;
  };

  return (
    <NewBlock
      title="Add a new block"
      submit={submit}
      tabs={tabs}
      schema="UserBlockAdd"
      fieldCallback={fieldCallback}
    />
  );
};
