// import { Api } from "@metablock/components";
import { getBlock } from "@metablock/core";
import { CmsPaginate, NotFound } from "@metablock/react";
import Footer from "@metaweb/Components/Footer";
import React from "react";
import { Route, Routes } from "react-router-dom";
import docs from "../../content/docs/collection.json";
import About from "./About/Index";
import { DocsList } from "./Docs";
import Home from "./LandingPage";
import { PageLogin, PageSignUp } from "./Login";
import Partners from "./Partners";

const Public = () => {
  const block = getBlock();
  return (
    <>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/partners" element={<Partners />} />
        <Route path={block.plugins.account.login_url} element={<PageLogin />} />
        <Route
          path={block.plugins.account.signup_url}
          element={<PageSignUp />}
        />
        <Route
          path="/docs/*"
          element={
            <CmsPaginate
              topic="docs"
              path="/docs"
              slug={docs.slug}
              Component={DocsList}
            />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Public;
