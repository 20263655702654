import { NotFound } from "@metablock/react";
import DLFactory from "@metaweb/Components/DescriptionList";
import { formatDateTime } from "@metaweb/utils/dates";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useAsync } from "react-use";
import SpaceClient from "../store";

const AccountSettings = ({ spaceClient }: { spaceClient: SpaceClient }) => {
  const { value, loading } = useAsync(async () => {
    return await spaceClient.accountSettings();
  }, [spaceClient.spaceId]);
  if (loading) return null;
  if (!value) return <NotFound />;
  const Row = DLFactory({ sm: 3 });
  return (
    <Box>
      <Typography variant="h5" component="h3" paragraph>
        Service Account Settings
      </Typography>
      <Row entry="Service Account Auth Key">
        <code>{value.service_auth_key}</code>
      </Row>
      <Row entry="Service Account Auth Created">
        <code>{formatDateTime(new Date(value.service_auth_created))}</code>
      </Row>
    </Box>
  );
};

export default AccountSettings;
