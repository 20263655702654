import { useStores } from "@metaweb/stores";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";

const UserMenu = () => {
  const { authStore } = useStores();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    await authStore.logout();
  };

  return (
    <Box>
      <IconButton
        aria-label="account of current user"
        aria-controls="user-account-menu"
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="user-account-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to="/app/account">
          Profile
        </MenuItem>
        <MenuItem onClick={signOut}>Sign out</MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
