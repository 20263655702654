import { Org } from "@metablock/core";
import { CrudForm, Crumb, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";

const OrgExtensionAdd = ({ org, tabs }: { org: Org; tabs: Crumb[] }) => {
  const { metablock } = useStores();

  const submit = async (data: any) => {
    await metablock.orgs.createExtension(org.id, data);
  };
  const title = "Create an Extension";

  return (
    <Title title={title} tabs={tabs}>
      <CrudForm
        maxWidth={false}
        schema={metablock.schemaLoader("ExtensionAdd")}
        submit={submit}
      />
    </Title>
  );
};

export default OrgExtensionAdd;
