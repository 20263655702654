import { getBlock } from "@metablock/core";
import { Link } from "@metablock/react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import logos from "../Components/logos";

const ComingSoon = () => {
  const block = getBlock();
  return (
    <Container maxWidth="sm">
      <Box pt={10} pb={20} textAlign="center">
        <Box pb={4}>
          <Link to="/">
            <img src={logos.avatarDark} height="80" />
          </Link>
        </Box>
        <Typography variant="h4" paragraph>
          Metablock is Coming Soon!
        </Typography>
        <Typography variant="h6">
          We are still working on some features before opening the website to
          users
        </Typography>
        <Typography variant="h6">
          If you have an account already,{" "}
          <Link to={block.plugins.account.login_url}>login here</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default ComingSoon;
