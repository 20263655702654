import { Asset, Block, StaticData } from "@metablock/core";
import { ApiDataGrid, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import prettyBytes from "pretty-bytes";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useAsync } from "react-use";
import { rowDate } from "../../utils/dates";

const assetUrl = (block: Block): string =>
  `https://assets.metablock.io/blocks/${block.id}/assets`;

const AssetHome = ({ block }: { block: Block }) => {
  const { metablock } = useStores();
  const onDrop = React.useCallback(async (acceptedFiles: any) => {
    await metablock.blocks.uploadAssets(block.id, acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop });
  const props = getRootProps({ isFocused, isDragAccept, isDragReject });
  const { loading, value } = useAsync(async () => {
    return metablock.blocks.assets(block.id);
  }, [block.id]);
  const columns = React.useMemo(
    () => [
      {
        key: "name",
        name: "File Name",
        formatter: ({ row }: any) => (
          <Link
            target="_blank"
            rel="noopener"
            href={`${assetUrl(block)}/${row.name}`}
          >
            {row.name}
          </Link>
        ),
      },
      {
        key: "last_modified",
        name: "Last Modified",
        formatter: rowDate("last_modified"),
      },
      {
        key: "size",
        name: "Size",
        formatter: ({ row }: any) => (
          <Box sx={{ textAlign: "right" }}>{prettyBytes(row.size)}</Box>
        ),
      },
    ],
    []
  );
  if (loading) return null;
  const api = new StaticData<Asset>(value || []);
  const size = api.data.reduce((s, asset) => s + asset.size, 0);

  return (
    <Title pageTitle={`${block.full_name} Static Assets`} title="Static Assets">
      <Typography paragraph>
        Manage the block static assets storage. Files on the block storage are
        served from the root address{" "}
        <Link href={assetUrl(block)}>{assetUrl(block)}</Link>. It is possible to
        map these to the block domain via the static plugin.
      </Typography>
      <Typography paragraph>
        Total asset size: <strong>{prettyBytes(size)}</strong>
      </Typography>
      <ApiDataGrid api={api} columns={columns} />
      <Box {...props} mt={2}>
        <input {...getInputProps()} />
        <Typography
          p={4}
          sx={{ border: 1, borderRadius: 2, borderColor: "grey.500" }}
        >
          Drag n drop some files here, or click to select files
        </Typography>
      </Box>
    </Title>
  );
};

export default AssetHome;
