import { Block } from "@metablock/core";
import { NotFound, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";
import ReactJson from "react-json-view";
import { useAsync } from "react-use";

const Routing = ({ block }: { block: Block }) => {
  const { metablock } = useStores();
  const { loading, value } = useAsync(async () => {
    return metablock.blocks.get(block.id);
  }, [block.id]);
  if (loading) return null;
  if (!value) return <NotFound />;
  return (
    <Title title="Routing Configuration">
      <ReactJson src={value} theme="tomorrow" />
    </Title>
  );
};

export default Routing;
