import { Block, BlockPlugin } from "@metablock/core";
import { ApiDataGrid, Link } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import { gridStyle } from "@metaweb/theme";
import { useTheme } from "@mui/material";
import React from "react";
import { booleanFormatter } from "../../Components/tableFormatters";
import { ExtensionApp } from "../../Extensions";
import columnAddEntry from "../Views/columnAddEntry";

export const pluginColumns = (app: ExtensionApp, action: any) => {
  return [
    {
      key: "name",
      name: "name",
      renderCell: ({ row }: any) => (
        <Link to={app.getUrl(row.name)}>{row.name}</Link>
      ),
    },
    {
      key: "extension_name",
      name: "Extension",
      renderCell: ({ row }: any) => (
        <Link to={app.getUrl(row.name)}>{row.extension_name}</Link>
      ),
    },
    { key: "description", name: "description" },
    { key: "active", name: "active", renderCell: booleanFormatter("active") },
    action,
  ];
};

const PluginAdd = ({
  app,
  cfg,
  block,
}: {
  app: ExtensionApp;
  cfg: any;
  block: Block;
}) => {
  const { metablock } = useStores();
  const plugins = cfg.plugins || [];
  const theme = useTheme();
  const available = new Set(plugins.map((plugin: any) => plugin.name));
  const api = metablock.plugins.loader();

  const columns = React.useMemo(
    () =>
      pluginColumns(
        app,
        columnAddEntry(
          (plugin: BlockPlugin) => {},
          (plugin: BlockPlugin) => `Add ${plugin.name} plugin to ${block.url}`
        )
      ),
    [app.baseUrl]
  );

  return (
    <ApiDataGrid
      api={api}
      columns={columns}
      rowClass={(row: any) =>
        available.has(row.name) ? "installed" : undefined
      }
      sx={{
        " .installed": {
          backgroundColor: "#eee",
        },
      }}
      {...gridStyle(theme)}
    />
  );
};

export default PluginAdd;
