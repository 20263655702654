import { Org } from "@metablock/core";
import { TextField, useForm } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";

const OrgSettings = (props: any) => {
  const { orgStore } = useStores();
  const org = orgStore.currentOrg as Org;
  const form = useForm({
    defaultValues: org,
    handleSubmit: async (data: any, dirty: any) => {
      if (Object.keys(dirty).length) {
        await orgStore.updateOrg(org.id, dirty);
        const errors = orgStore.errors;
        if (errors && errors.status === 422) {
          form.setErrors(errors.errors, true);
        }
      }
    },
  });
  return (
    <Grid container spacing={4}>
      <Grid item sm={12} md={6}>
        <form onSubmit={form.onSubmit()} noValidate>
          <TextField
            form={form}
            fullWidth
            required
            name="short_name"
            margin="normal"
            variant="outlined"
            label="Organization short name"
            helperText="Used in the urls"
          />
          <TextField
            form={form}
            fullWidth
            name="full_name"
            margin="normal"
            label="Organization full name"
            variant="outlined"
            helperText="The full name of the organization or a description"
          />
          <TextField
            form={form}
            fullWidth
            required
            name="email"
            margin="normal"
            label="Organization email"
            variant="outlined"
            helperText="Email address for communication"
          />
          <TextField
            form={form}
            fullWidth
            name="website"
            margin="normal"
            label="Organization website"
            variant="outlined"
          />
          <FormControl margin="normal">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </FormControl>
        </form>
      </Grid>
      <Grid item sm={12} md={6}>
        <Box borderColor="error.main" color="error.main" border={1}>
          <Card>
            <CardHeader
              title={
                <Typography color="error" variant="h5">
                  Danger Zone
                </Typography>
              }
            />
            <CardContent>Danger</CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrgSettings;
