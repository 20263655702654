import { Org } from "@metablock/core";
import { ApiDataGrid, Crumb, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import { gridStyle } from "@metaweb/theme";
import { useTheme } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  booleanFormatter,
  linkFormatter,
} from "../../Components/tableFormatters";
import OrgExtensionAdd from "./ExtensionAdd";

const OrgExtensionsHome = ({ org, baseUrl }: { org: Org; baseUrl: string }) => {
  const tabs: Crumb[] = [
    {
      text: "all extensions",
      to: baseUrl,
    },
    {
      text: "new extension",
      to: `${baseUrl}/new`,
    },
  ];
  return (
    <Routes>
      <Route path="" element={<OrgExtensions tabs={tabs} org={org} />} />
      <Route path="/new" element={<OrgExtensionAdd tabs={tabs} org={org} />} />
    </Routes>
  );
};

const OrgExtensions = ({ org, tabs }: { org: Org; tabs: Crumb[] }) => {
  const { metablock } = useStores();
  const theme = useTheme();
  const api = metablock.orgs.extensionLoader(org.id);
  const columns = React.useMemo(
    () => [
      {
        key: "name",
        name: "Name",
        formatter: linkFormatter("name", "/app/extensions"),
      },
      {
        key: "marketplace",
        name: "Marketplace",
        formatter: booleanFormatter("marketplace"),
      },
      {
        key: "homepage_url",
        name: "Homepage",
        formatter: linkFormatter("homepage_url"),
      },
    ],
    []
  );
  return (
    <Title title={`${org.short_name} extensions`} tabs={tabs}>
      <ApiDataGrid api={api} columns={columns} {...gridStyle(theme)} />
    </Title>
  );
};

export default OrgExtensionsHome;
