import { Org } from "@metablock/core";
import { CrudForm, Crumb, NotFound, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";

const ExtensionUpdate = ({ org, tabs }: { org: Org; tabs: Crumb[] }) => {
  const { metablock } = useStores();
  const { extensionId } = useParams() as { extensionId: string };
  const { loading, value } = useAsync(async () => {
    return await metablock.extensions.get(extensionId);
  }, [extensionId]);
  if (loading) return null;
  if (!value) return <NotFound />;

  const submit = async (data: any) => {
    await metablock.extensions.update(value.id, data);
  };

  const title = `Extension ${value.name}`;

  return (
    <Title title={title} tabs={tabs}>
      <CrudForm
        defaults={value}
        maxWidth={false}
        schema={metablock.schemaLoader("ExtensionAdd")}
        submit={submit}
      />
    </Title>
  );
};

export default ExtensionUpdate;
