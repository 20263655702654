import { Space, SpaceExtension } from "@metablock/core";
import {
  ApiDataGrid,
  Crumb,
  Link,
  RouteComponent,
  Title,
} from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { gridStyle } from "@metaweb/theme";
import { useTheme } from "@mui/material";
import { ExtensionApp, useMetablockApp } from "../../Extensions";
import ExtensionUpdate from "./ExtensionUpdate";

interface ExtensionProps {
  app: ExtensionApp;
  tabs: Crumb[];
  space: Space;
  extensions: SpaceExtension[];
}
const ExtensionsHome = ({
  space,
  baseUrl,
  extensions,
}: {
  space: Space;
  baseUrl: string;
  extensions: SpaceExtension[];
}) => {
  const app = useMetablockApp({
    baseUrl,
    routes: [
      {
        label: "extensions",
        url: "",
        Component: Extensions,
      },
      {
        label: "extension",
        url: "/:extension_name",
        Component: ExtensionUpdate,
      },
    ],
  });
  const tabs: Crumb[] = [
    {
      text: "extensions",
      to: app.baseUrl,
    },
  ];

  return (
    <Routes>
      {app.routes.map(({ url, Component }: RouteComponent) => (
        <Route
          path={url}
          key={url}
          element={
            <Component
              tabs={tabs}
              app={app}
              space={space}
              extensions={extensions}
            />
          }
        />
      ))}
    </Routes>
  );
};

const Extensions = ({ app, tabs, space, extensions }: ExtensionProps) => {
  const { metablock } = useStores();
  const api = metablock.spaces.extensionsLoader(space.id);
  const available = new Set(
    extensions.map((extension: SpaceExtension) => extension.name)
  );
  const api2 = metablock.extensions.loader();
  const theme = useTheme();

  const columns = React.useMemo(
    () => [
      {
        key: "name",
        name: "name",
        formatter: ({ row }: any) => (
          <Link to={app.getUrl(row.name)}>{row.name}</Link>
        ),
      },
      { key: "description", name: "description" },
      { key: "org_name", name: "created by" },
    ],
    [app.baseUrl]
  );

  return (
    <Title title={`${space.name} space extensions`} tabs={tabs}>
      <Box>
        <ApiDataGrid api={api} columns={columns} {...gridStyle(theme)} />
      </Box>
      <Box mt={2}>
        <Typography variant="h6" align="center" pb={2}>
          Available to Install
        </Typography>
        <ApiDataGrid
          api={api2}
          columns={columns}
          rowClass={(row: any) =>
            available.has(row.name) ? "installed" : undefined
          }
          sx={{
            " .installed": {
              backgroundColor: "#eee",
            },
          }}
          {...gridStyle(theme)}
        />
      </Box>
    </Title>
  );
};

export default ExtensionsHome;
