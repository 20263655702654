import {
  AppForm,
  CrudForm,
  CrudFormSubmitType,
  Crumb,
  FieldCallbackType,
  Title,
} from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";

const NewBlock = ({
  tabs,
  title,
  schema,
  fieldCallback,
  submit,
}: {
  tabs: Crumb[];
  title: string;
  schema: string;
  fieldCallback?: FieldCallbackType;
  submit?: CrudFormSubmitType;
}) => {
  const { metablock } = useStores();

  const createBlock = async (data: any) => {
    return await metablock.blocks.create(data);
  };

  return (
    <Title title={title} tabs={tabs}>
      <AppForm>
        <CrudForm
          maxWidth={false}
          schema={metablock.schemaLoader(schema)}
          submit={submit || createBlock}
          fieldCallback={fieldCallback}
        />
      </AppForm>
    </Title>
  );
};

export default NewBlock;
