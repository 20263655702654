import { timeFormat } from "d3-time-format";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

// @ts-ignore
TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

export const formatDateTime = timeFormat("%H:%M %B %d, %Y");
export const formatTimeAgo = (date: Date): string =>
  timeAgo.format(date) as string;

export const rowDate = (code: string, converter?: any) => {
  converter = converter || formatDateTime;
  return ({ row }: any) => {
    const ts = row[code];
    if (!ts) return "";
    const dte =
      typeof ts === "string" ? new Date(ts) : new Date(Math.floor(0.001 * ts));
    return converter(dte);
  };
};
