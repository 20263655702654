import { useStores } from "@metaweb/stores";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React from "react";
import { useAsync } from "react-use";
import DLFactory from "../../Components/DescriptionList";
import { formatDateTime } from "../../utils/dates";

const BlockCertificate = (props: any) => {
  const { metablock } = useStores();
  const { block } = props;
  const { space } = block;

  const { loading, value } = useAsync(async () => {
    try {
      const url = `${metablock.apiUrl}/services/${block.id}/certificate`;
      const response = await metablock.get(url);
      return response.data;
    } catch (err) {
      return null;
    }
  }, [block.id]);

  if (loading) return null;
  if (!value) return null;

  const Row = DLFactory({ sm: 3 });

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader title="Certificate" />
      <CardContent>
        <Row entry="Issued on">{formatDateTime(new Date(value.issued_on))}</Row>
        <Row entry="Expires on">
          {formatDateTime(new Date(value.expires_on))}
        </Row>
        <Row entry="Isuer">{value.issuer.O}</Row>
        <Row entry="Version">{value.version}</Row>
      </CardContent>
    </Card>
  );
};

export default BlockCertificate;
