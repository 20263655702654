import { User } from "@metablock/core";
import { RouteComponent } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes } from "react-router-dom";
import { useMetablockApp } from "../../Extensions";
import ErrorFallback from "../Components/ErrorFallback";
import TabRoutes from "../Components/TabRoutes";
import Account from "./Account";
import BlocksHome from "./Blocks";
import Tokens from "./Tokens";

const AccountHome = () => {
  const { userStore } = useStores();
  const user = userStore.current as User;
  const bits = [];
  if (user.first_name) bits.push(user.first_name);
  if (user.last_name) bits.push(user.last_name);
  const name = bits ? bits.join(" ") : user.username;
  const app = useMetablockApp({
    baseUrl: "/app/account",
    routes: [
      {
        label: "blocks",
        url: "blocks",
        routePath: "blocks/*",
        Component: BlocksHome,
      },
      {
        label: "Settings",
        url: "settings",
        Component: Account,
      },
      {
        label: "Security",
        url: "security",
        Component: Tokens,
      },
    ],
  });
  const Tabs = (opts: any) => {
    const { Component, props, url } = opts;
    return (
      <TabRoutes
        routes={app.getNavigation()}
        value={url}
        textColor="primary"
        indicatorColor="primary"
        sx={{ backgroundColor: "palette.background.default" }}
        maxWidth="lg"
        centered
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Component baseUrl={url} {...props} />
        </ErrorBoundary>
      </TabRoutes>
    );
  };

  return (
    <Box bgcolor="action.hover">
      <Container maxWidth="md">
        <Box pb={4} pt={4}>
          <Typography variant="h4" component="h1" paragraph>
            {name}
          </Typography>
          <Typography variant="subtitle2" component="h3" paragraph>
            {user.email}
          </Typography>
        </Box>
      </Container>
      <Routes>
        <Route path="" element={<Navigate to={app.getUrl("blocks")} />} />
        {app.routes.map(({ url, routePath, Component }: RouteComponent) => (
          <Route
            key={url}
            path={routePath || url}
            element={<Tabs Component={Component} url={app.getUrl(url)} />}
          />
        ))}
      </Routes>
    </Box>
  );
};

export default AccountHome;
