import { Crumb } from "@metablock/react";
import React from "react";
import {
  CodeIcon,
  DocsIcon,
  ExtensionIcon,
  HomeIcon,
  SettingsIcon,
} from "../Components/Icons";

const nav = (others?: Array<Crumb>) => {
  const Main: Array<Crumb> = [
    {
      text: "Home",
      icon: <HomeIcon />,
      to: "/",
    },
    {
      text: "Docs",
      icon: <DocsIcon />,
      to: "/docs",
    },
    {
      text: "Plugins",
      icon: <ExtensionIcon />,
      to: "/app/plugins",
    },
  ];

  const User: Array<Crumb> = [
    {
      text: "Settings",
      icon: <SettingsIcon />,
      to: "/app/account/settings",
    },
    {
      text: "Tokens",
      icon: <CodeIcon />,
      to: "/app/account/security",
    },
  ];
  const result = [Main, User];
  if (others) {
    result.push(others);
  }
  return result;
};

export default nav;
