import { Org } from "@metablock/core";
import { ApiDataGrid, Crumb, RouteComponent, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import { gridStyle } from "@metaweb/theme";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { ExtensionApp, useMetablockApp } from "../../Extensions";

interface MemberProps {
  app: ExtensionApp;
  tabs: Crumb[];
  org: Org;
}

const combine = (first: string, second: string): string => {
  return first ? (second ? `${first} ${second}` : first) : second || "";
};

const OrgMembers = ({ org }: { org: Org }) => {
  const app = useMetablockApp({
    baseUrl: `/app/orgs/${org.short_name}/members`,
    routes: [
      {
        label: "members",
        url: "",
        Component: Members,
      },
      {
        label: "member",
        url: "/:member_name",
        Component: Member,
      },
    ],
  });
  const tabs: Crumb[] = [
    {
      text: "members",
      to: app.baseUrl,
    },
  ];
  return (
    <Routes>
      {app.routes.map(({ url, Component }: RouteComponent) => (
        <Route
          path={url}
          key={url}
          element={<Component tabs={tabs} app={app} org={org} />}
        />
      ))}
    </Routes>
  );
};

const Members = ({ app, tabs, org }: MemberProps) => {
  const { metablock } = useStores();
  const api = metablock.orgs.membersLoader(org.id);
  const theme = useTheme();
  const columns = React.useMemo(
    () => [
      {
        key: "user_id",
        name: "User ID",
      },
      {
        key: "email",
        name: "Email",
      },
      {
        key: "full_name",
        name: "Full name",
        formatter: ({ row }: any) => (
          <Box component="span">{combine(row.first_name, row.last_name)}</Box>
        ),
      },
      {
        key: "roles",
        name: "Roles",
      },
    ],
    []
  );
  return (
    <Title title={`${org.short_name} roles`} tabs={tabs}>
      <ApiDataGrid api={api} columns={columns} {...gridStyle(theme)} />
    </Title>
  );
};

const Member = ({ app, tabs, org }: MemberProps) => {
  return (
    <Title title={`${org.short_name} roles`} tabs={tabs}>
      ok
    </Title>
  );
};

export default OrgMembers;
