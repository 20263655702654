import { Block } from "@metablock/core";
import { useStores } from "@metaweb/stores";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import DangerZone from "../Components/DangerZone";

const BlockActions = ({ block }: { block: Block }) => {
  const { messageStore, metablock } = useStores();
  const [deleted, setDeleted] = useState(false);

  if (deleted) {
    messageStore.success(`Successfully deleted block ${block.id}`);
    return <Navigate to="/app/blocks" />;
  }

  const deleteBlock = async () => {
    try {
      await metablock.blocks.delete(block.id);
      setDeleted(true);
    } catch (errors) {
      messageStore.error("Could not delete block");
    }
  };

  const transferBlock = () => {
    return true;
  };

  return (
    <DangerZone
      items={[
        { text: "Delete Block", action: deleteBlock },
        { text: "Transfer Block", action: transferBlock },
      ]}
    />
  );
};

export default BlockActions;
