import Alert from "@mui/material/Alert";
import React from "react";

const ErrorFallback = (props: any) => {
  const { error } = props;
  return (
    <Alert severity="error">
      Something went wrong <pre>{error.message}</pre>
    </Alert>
  );
};

export default ErrorFallback;
