import { ApiDataGrid, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";
import { formatDateTime } from "../../utils/dates";

const Deployments: React.FC = (props: any) => {
  const { block, cfg } = props;
  const { metablock } = useStores();
  const bits = cfg.config.deploy_url?.split("/");
  const deployId = bits ? bits[bits.length - 1] : "";
  const api = metablock.blocks.deploymentsLoader(block.id);

  const columns = React.useMemo(
    () => [
      {
        key: "id",
        name: "id",
      },
      {
        key: "name",
        name: "name",
      },
      {
        key: "env",
        name: "environment",
      },
      {
        key: "created",
        name: "created",
        formatter: ({ row }: any) => (
          <span>{formatDateTime(new Date(row.created))}</span>
        ),
      },
    ],
    []
  );

  const style = (numRows: number) => {
    return { height: 35 * (Math.min(numRows, 25) + 1) + 2 };
  };

  return (
    <Title pageTitle={`${block.domain} Deployments`} title="Deployments">
      <ApiDataGrid
        api={api}
        columns={columns}
        sx={{
          " .deployed": {
            backgroundColor: "#eee",
          },
        }}
        style={style}
        rowClass={(row: any) => (row.id == deployId ? "deployed" : undefined)}
      />
    </Title>
  );
};

export default Deployments;
