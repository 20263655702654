import { CrudForm, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";

const SpaceAdd = (props: any) => {
  const { tabs } = props;
  const { spaceStore, metablock } = useStores();
  const values: Record<string, any> = {};
  if (spaceStore.space) values.space_id = spaceStore.space.id;

  const submit = async (data: any) => {
    await spaceStore.create(data);
  };

  return (
    <Title title="Create a new Space" tabs={tabs}>
      <CrudForm
        maxWidth="sm"
        schema={metablock.schemaLoader("SpaceAdd")}
        submit={submit}
      />
    </Title>
  );
};

export default SpaceAdd;
