import Box from "@mui/material/Box";
import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";

interface CardProps {
  icon: any;
  children: React.ReactNode;
}

const IconCard = (props: CardProps) => {
  const { icon, children } = props;
  return (
    <MuiCard>
      <CardContent>
        <Box p={2}>
          <Typography variant="h4" align="center">
            {icon}
          </Typography>
        </Box>
        <Typography variant="h6">{children}</Typography>
      </CardContent>
    </MuiCard>
  );
};

export default IconCard;
