import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";

interface ZoneProps {
  sx?: any;
  items: any[];
}

const DangerZone = (props: ZoneProps) => {
  const { items, sx } = props;
  const sxx = {
    borderColor: "error.main",
    color: "error.main",
    border: 1,
    borderRadius: 1,
    ...sx,
  };
  return (
    <Card sx={sxx}>
      <CardHeader title={<Typography variant="h5">Danger Zone</Typography>} />
      <CardContent>
        <List>
          {items.map((item, index) => (
            <ListItem key={index} disableGutters>
              <ListItemText
                secondaryTypographyProps={{ component: "div" }}
                secondary={<DangerItem item={item} />}
              />
            </ListItem>
          ))}
        </List>
        <Button />
      </CardContent>
    </Card>
  );
};

const DangerItem = (props: any) => {
  const { item } = props;
  return (
    <Button variant="contained" color="error" onClick={item.action}>
      {item.text}
    </Button>
  );
};

export default DangerZone;
