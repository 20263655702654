import { AddIcon } from "@metaweb/Components/Icons";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

const columnAddEntry = (action: any, tooltip?: any, icon?: any) => {
  return {
    name: "",
    key: "",
    width: 30,
    renderCell: ({ row }: any) => {
      const title = tooltip ? tooltip(row) : `Add ${row.id}`;
      return (
        <Tooltip title={title}>
          <IconButton color="info" onClick={() => action(row)}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
};

export default columnAddEntry;
