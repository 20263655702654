import {
  ApiDataGrid,
  ExtensionProps,
  RouteComponent,
  Title,
} from "@metablock/react";
import { useMetablockApp } from "@metaweb/Extensions";
import { useStores } from "@metaweb/stores";
import { gridSmall } from "@metaweb/theme";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { formatDateTime } from "../../utils/dates";
import SpaceClient from "../store";
import AccountSettings from "./Settings";

const Accounts = ({ extension, baseUrl }: ExtensionProps) => {
  const { metablock } = useStores();
  const spaceClient = new SpaceClient(metablock, extension.space_id);
  const app = useMetablockApp({
    baseUrl,
    routes: [
      {
        label: "users",
        url: "",
        Component: SpaceUsers,
      },
      {
        label: "settings",
        url: "settings",
        Component: AccountSettings,
      },
    ],
  });

  return (
    <Title title={extension.description} tabs={app.getNavigation()}>
      <Routes>
        {app.routes.map(({ url, Component }: RouteComponent) => (
          <Route
            path={url}
            key={url}
            element={<Component spaceClient={spaceClient} />}
          />
        ))}
      </Routes>
    </Title>
  );
};

const SpaceUsers = ({ spaceClient }: { spaceClient: SpaceClient }) => {
  const api = spaceClient.accountsLoader();
  const theme = useTheme();

  const columns = React.useMemo(
    () => [
      {
        key: "email",
        name: "Email",
      },
      {
        key: "first_name",
        name: "First name",
      },
      {
        key: "last_name",
        name: "Last name",
      },
      {
        key: "created",
        name: "Member since",
        formatter: ({ row }: any) => (
          <Box component="span">{formatDateTime(new Date(row.created))}</Box>
        ),
      },
      {
        key: "status",
        name: "Status",
        formatter: ({ row }: any) => (
          <Chip
            color={statusColor(row.status)}
            label={row.status.toLowerCase()}
          />
        ),
      },
    ],
    []
  );

  return <ApiDataGrid api={api} columns={columns} {...gridSmall(theme)} />;
};

const ACTIVE = "ACTIVE";

const statusColor = (status: string) => {
  return status === ACTIVE ? "primary" : "secondary";
};

export default Accounts;
