import { Org, OrgRole } from "@metablock/core";
import {
  ApiDataGrid,
  Crumb,
  Link,
  NotFound,
  RouteComponent,
  Title,
} from "@metablock/react";
import Todo from "@metaweb/Components/Todo";
import { useStores } from "@metaweb/stores";
import { gridStyle } from "@metaweb/theme";
import { useTheme } from "@mui/material";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { ExtensionApp, useMetablockApp } from "../../Extensions";

interface RoleProps {
  app: ExtensionApp;
  tabs: Crumb[];
  org: Org;
}

const OrgRoles = ({ org, baseUrl }: { org: Org; baseUrl: string }) => {
  const app = useMetablockApp({
    baseUrl,
    routes: [
      {
        label: "roles",
        url: "",
        Component: Roles,
      },
      {
        label: "role",
        url: "/new",
        Component: NewRole,
      },
      {
        label: "role",
        url: "/:role_name",
        Component: Role,
      },
    ],
  });
  const tabs: Crumb[] = [
    {
      text: "roles",
      to: baseUrl,
    },
    {
      text: "new role",
      to: `${baseUrl}/new`,
    },
  ];
  return (
    <Routes>
      {app.routes.map(({ url, Component }: RouteComponent) => (
        <Route
          path={url}
          key={url}
          element={<Component tabs={tabs} app={app} org={org} />}
        />
      ))}
    </Routes>
  );
};

const Roles = ({ app, tabs, org }: RoleProps) => {
  const { metablock } = useStores();
  const theme = useTheme();
  const api = metablock.orgs.rolesLoader(org.id);
  const columns = React.useMemo(
    () => [
      {
        key: "name",
        name: "Name",
        formatter: ({ row }: any) => (
          <Link to={app.getUrl(row.name)}>{row.name}</Link>
        ),
      },
      {
        key: "description",
        name: "Description",
      },
      {
        key: "members",
        name: "Members",
      },
    ],
    []
  );
  return (
    <Title title={`${org.short_name} roles`} tabs={tabs}>
      <ApiDataGrid api={api} columns={columns} {...gridStyle(theme)} />
    </Title>
  );
};

const Role = ({ app, tabs, org }: RoleProps) => {
  const { role_name } = useParams() as { role_name: string };
  const { metablock } = useStores();
  const { loading, value } = useAsync(async () => {
    return await metablock.orgs.getRole(org.id, role_name);
  }, [org.id, role_name]);
  if (loading) return null;
  if (!value) return <NotFound />;
  const role = value as OrgRole;

  return (
    <Title title={`${org.short_name} ${role.name} role`} tabs={tabs}>
      <Todo />
    </Title>
  );
};

const NewRole = ({ app, tabs, org }: RoleProps) => {
  return (
    <Title title={`Add a new role to ${org.short_name}`} tabs={tabs}>
      <Todo />
    </Title>
  );
};

export default OrgRoles;
