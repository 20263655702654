import { Link } from "@metablock/react";
import MuiLink from "@mui/material/Link";
import MuiList from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

type Direction = "vertical" | "horizontal";

interface ListProps {
  items: any[];
  direction?: Direction;
}

const styles = {
  horizontal: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  horizontalItem: {
    width: "auto",
    padding: 0,
    paddingRight: 2,
  },
  vertical: {
    padding: 0,
  },
  verticalItem: {
    padding: 0,
  },
  icon: {
    minWidth: "auto",
    paddingRight: 0.5,
  },
};

const List = (props: ListProps) => {
  const { items, direction = "vertical" } = props;
  const sx =
    direction === "vertical" ? styles.verticalItem : styles.horizontalItem;
  return (
    <MuiList sx={styles[direction]}>
      {items
        .filter((item) => item.text || item.href || item.to)
        .map((item, index) => (
          <ListItem key={index} sx={sx} disableGutters>
            <ListItemIcon sx={styles.icon}>{item.icon}</ListItemIcon>
            <ListItemText
              secondaryTypographyProps={{ component: "div" }}
              secondary={
                item.href ? (
                  <MuiLink href={item.href}>{item.text || item.href}</MuiLink>
                ) : item.to ? (
                  <Link to={item.to}>{item.text || item.to}</Link>
                ) : (
                  item.text
                )
              }
            />
          </ListItem>
        ))}
    </MuiList>
  );
};

export default List;
