import { Page, Unsplash } from "@metablock/react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { darkTheme } from "../../theme";
import Header from "../Components/Header";
import { title } from "../styles";

const About: React.FC = () => {
  return (
    <Page title="About" prefix={true}>
      <Header light={false} />
      <Unsplash
        filter={0.8}
        photoId="hgxAdNZ_ccg"
        maxHeight="350px"
        credit={false}
      >
        <ThemeProvider theme={darkTheme}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                  component="h1"
                  sx={title}
                >
                  About Metablock
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
      </Unsplash>
    </Page>
  );
};

export default About;
