import { getBlock } from "@metablock/core";
import {
  createStores as createMetablockStores,
  MetablockStore,
  UserOrgStore,
} from "@metablock/store";
import MetaStore from "./meta";
import SpaceStore from "./spaces";

export interface MetablockStoreIO extends MetablockStore {
  orgStore: UserOrgStore;
  spaceStore: SpaceStore;
  metaStore: MetaStore;
}

export const createStores = (): MetablockStoreIO => {
  const block = getBlock();
  const account = block.plugins.account;
  const { userStore, commonStore, ...stores } = createMetablockStores(
    account.api_url,
    block.name
  );
  const orgStore = new UserOrgStore(userStore);

  return {
    commonStore,
    userStore,
    orgStore,
    spaceStore: new SpaceStore(orgStore),
    metaStore: new MetaStore(commonStore),
    ...stores,
  } as MetablockStoreIO;
};
