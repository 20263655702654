import { Plugin } from "@metablock/core";
import { Link, List, Page, ScrollableList } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import CheckIcon from "@mui/icons-material/Check";
import ExtensionIcon from "@mui/icons-material/Extension";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";

const Plugins: React.FC = (props: any) => {
  const { metablock } = useStores();
  const pathname = "/app/plugins";
  const api = metablock.plugins.loader();

  const getPluginEntry = (plugin: Plugin) => ({
    title: (
      <>
        <Link to={`${pathname}/${plugin.name}`}>{plugin.name}</Link>
        <Link
          sx={{ float: "right" }}
          to={`/app/extensions/${plugin.extension_id}`}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ borderRadius: 2 }}
            startIcon={<ExtensionIcon />}
          >
            {plugin.extension_name}
          </Button>
        </Link>
      </>
    ),
    children: <PluginEntry plugin={plugin} />,
  });

  return (
    <Page title="Plugins & Extensions">
      <Box bgcolor="action.hover">
        <Container maxWidth="md">
          <Box pb={4} pt={4}>
            <Typography variant="h4" component="h1" paragraph>
              Extend your Spaces and Blocks
            </Typography>
            <Typography variant="h6" component="h3" paragraph>
              Extensions add functionalities to your Spaces while Plugins acts
              on blocks and are part of an extension. Extensions and plugins are
              created by metablock organizations.
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="md">
        <Box pt={8}>
          <ScrollableList api={api} listEntry={getPluginEntry} />
        </Box>
      </Container>
    </Page>
  );
};

const PluginEntry = ({ plugin }: { plugin: Plugin }) => {
  return (
    <>
      <Typography variant="body2" component="div" paragraph>
        <List
          direction="horizontal"
          items={[
            {
              text: (
                <Typography variant="body1">
                  Created by <strong>{plugin.org_name}</strong>
                </Typography>
              ),
            },
            {
              icon: plugin.marketplace ? <CheckIcon /> : <NotInterestedIcon />,
              text: "marketplace",
            },
          ]}
        />
      </Typography>
      <Typography variant="body1">{plugin.description}</Typography>
    </>
  );
};

export default Plugins;
