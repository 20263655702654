import Box from "@mui/material/Box";
import React from "react";

export const RightBox = (props: any) => {
  const { sx, ...rest } = props;
  const sxRight = {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    ...sx,
  };
  return <Box sx={sxRight} {...rest} />;
};

export default RightBox;
