import { HttpResponse, Org, Space } from "@metablock/core";
import { CommonStore, UserOrgStore } from "@metablock/store";
import { action, computed, observable } from "mobx";

class SpaceStore {
  orgStore: UserOrgStore;

  @observable inProgress = false;
  @observable errors?: HttpResponse = undefined;
  @observable space?: Space = undefined;
  @observable genesisSpace?: Space = undefined;
  @observable orgSpaces: Record<string, Space[]> = {};
  @observable count = -1;

  constructor(orgStore: UserOrgStore) {
    this.orgStore = orgStore;
  }

  get commonStore(): CommonStore {
    return this.orgStore.commonStore;
  }

  get cli() {
    return this.commonStore.cli;
  }

  @computed
  get currentOrg(): Org | undefined {
    return this.orgStore?.currentOrg;
  }

  @computed
  get spaces(): Space[] | undefined {
    const org_id = this.currentOrg?.id || "";
    const spaces = this.orgSpaces[org_id];
    if (spaces && org_id && org_id === this.genesisSpace?.org_id)
      return [this.genesisSpace as Space].concat(spaces);
    else return spaces;
  }

  @action
  async getGenesis() {
    this.inProgress = true;
    this.errors = undefined;
    try {
      this.genesisSpace = await this.cli.genesis();
    } catch (errors: any) {
      this.errors = errors;
    } finally {
      this.inProgress = false;
    }
  }

  @action
  async get(space_id: string): Promise<Space> {
    const space = await this.cli.spaces.get(space_id);
    this.setSpace(space);
    return space;
  }

  @action
  async getList(query?: any) {
    this.inProgress = true;
    this.errors = undefined;
    const org_id = this.currentOrg?.id || "";
    try {
      const response = org_id
        ? await this.cli.spaces.orgSpaces(org_id, query)
        : await this.cli.spaces.getList(query);
      this.orgSpaces[org_id] = response.data as Space[];
      // const links = response.links;
      // this.count = links ? links.count : this.spaces.length;
    } catch (errors: any) {
      this.errors = errors;
    } finally {
      this.inProgress = false;
    }
  }

  @action
  async create(record: Record<string, any>) {
    this.inProgress = true;
    this.errors = undefined;
    try {
      const org_id = this.currentOrg?.id;
      const space = await this.cli.spaces.create(org_id || "<bad-org>", record);
      this.space = space;
      this.orgSpaces = {};
    } catch (errors: any) {
      this.errors = errors;
    } finally {
      this.inProgress = false;
    }
  }

  @action
  async update(space_id: string, record: Record<string, any>) {
    this.inProgress = true;
    this.errors = undefined;
    try {
      const space = await this.cli.spaces.update(space_id, record);
      this.setSpace(space);
    } catch (errors: any) {
      this.errors = errors;
    } finally {
      this.inProgress = false;
    }
  }

  @action
  async delete(space_id: string) {
    this.inProgress = true;
    this.errors = undefined;
    try {
      await this.cli.spaces.delete(space_id);
      this.orgSpaces = {};
    } catch (errors: any) {
      this.errors = errors;
    } finally {
      this.inProgress = false;
    }
  }

  @action
  setSpace(space?: Space) {
    this.space = space;
  }
}

export default SpaceStore;
