import { blue, grey } from "@mui/material/colors";
import { Theme, createTheme, responsiveFontSizes } from "@mui/material/styles";

export const drawerWidth = 240;
export const options = {
  drawerWidth: 240,
};

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

export const fontFamilyMono = "'Roboto Mono', monospace";

export const lightTheme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
      primary: {
        light: blue["700"],
        main: blue["800"],
        dark: blue["900"],
      },
      neutral: {
        main: "#fff",
      },
    },
  })
);

export const darkTheme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "dark",
      secondary: {
        light: grey["700"],
        main: grey["800"],
        dark: grey["900"],
      },
      neutral: {
        main: grey["900"],
      },
    },
  })
);

export const gridStyle = (theme: Theme, options?: any) => {
  const o = {
    rowHeight: 60,
    fontSize: 16,
    ...options,
  };
  return {
    rowHeight: o.rowHeight,
    className: theme.palette.mode === "dark" ? "rdg-dark" : "rdg-light",
    style: (numRows: number) => ({
      height: o.rowHeight * Math.min(numRows + 1, 25) + 2,
      fontSize: o.fontSize,
    }),
  };
};

export const gridSmall = (theme: Theme) => {
  return gridStyle(theme, { rowHeight: 35, fontSize: 14 });
};
