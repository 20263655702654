import { Typography } from "@mui/material";
import React from "react";

const Todo = (props: any) => {
  return (
    <Typography variant="h4" component="h1" paragraph>
      Todo
    </Typography>
  );
};

export default Todo;
