import { getBlock } from "@metablock/core";
import { Link } from "@metablock/react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React from "react";

const HeaderLinks = (props: any) => {
  const block = getBlock();

  return (
    <List sx={{}}>
      <ListItem>
        <Link to={block.plugins.account.login_url}>
          <Button variant="contained" color="inherit">
            Login
          </Button>
        </Link>
      </ListItem>
    </List>
  );
};

export default HeaderLinks;
