import { Copyright, Link, Section } from "@metablock/react";
import Grid from "@mui/material/Grid";
import { default as MuiLink } from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import React from "react";

const sx = {
  root: {
    backgroundColor: 'mode === "dark" ? secondary.dark : secondary.main',
    borderTop: "mode === dark ? secondary.main : secondary.dark",
    borderTopStyle: "1px solid",
  },
  container: {
    mt: 15,
    mb: 15,
    display: "flex",
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
  },
  icons: {},
  iconsWrapper: {
    height: 120,
  },
  list: {},
  listItem: {
    padding: 0,
  },
};

interface FooterLink {
  label: string;
  to?: string;
  href?: string;
}

interface FooterColumn {
  name: string;
  links: FooterLink[];
}

const columns: FooterColumn[] = [
  {
    name: "Company",
    links: [
      {
        label: "Home",
        to: "/",
      },
      {
        label: "About",
        to: "/about",
      },
      {
        label: "Partners",
        to: "/partners",
      },
    ],
  },
  {
    name: "Documentation",
    links: [
      {
        label: "Guides",
        to: "/docs",
      },
      {
        label: "API Reference",
        href: "https://api.metablock.io/v1/docs",
      },
    ],
  },
  {
    name: "Legal",
    links: [],
  },
];

const Footer: React.FC = () => {
  return (
    <Typography
      component="footer"
      variant="body2"
      color="textSecondary"
      sx={sx.root}
    >
      <Section component="div" pt={8} pb={5}>
        <Grid container spacing={4}>
          {columns.map((column) => (
            <Grid item sm={6} md={3} key={column.name}>
              <Typography component="h5">{column.name}</Typography>
              <List>
                {column.links.map((entry: any, index: number) => (
                  <ListItem sx={sx.listItem} key={index}>
                    {entry.to ? (
                      <Link color="inherit" to={entry.to}>
                        {entry.label}
                      </Link>
                    ) : (
                      <MuiLink color="inherit" href={entry.href}>
                        {entry.label}
                      </MuiLink>
                    )}
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
      </Section>
      <Section component="div" pb={5}>
        <Grid container>
          <Grid item sm={12}>
            <Copyright title="metablock" />
          </Grid>
        </Grid>
      </Section>
    </Typography>
  );
};

export default Footer;
