import { Space, SpaceExtension } from "@metablock/core";
import {
  CrudForm,
  Crumb,
  NotFound,
  SchemaEntry,
  Title,
} from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Typography from "@mui/material/Typography";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import DangerZone from "../Components/DangerZone";

const ExtensionActions = ({ extension }: { extension: SpaceExtension }) => {
  const { messageStore, metablock } = useStores();
  const [deleted, setDeleted] = React.useState(false);

  if (deleted) {
    messageStore.success(`Successfully removed extension ${extension.name}`);
    return <Navigate to="/app/plugins" />;
  }

  const removeExtension = async () => {
    try {
      await metablock.spaces.removeExtension(
        extension.space_id,
        extension.name
      );
      setDeleted(true);
    } catch (errors) {
      messageStore.error(`Could not remove extension ${extension.name}`);
    }
  };

  return (
    <DangerZone
      sx={{ mt: 3 }}
      items={[{ text: "Remove Extension", action: removeExtension }]}
    />
  );
};

const ExtensionUpdate = ({ tabs, space }: { tabs: Crumb[]; space: Space }) => {
  const { metablock } = useStores();
  const { extension_name } = useParams() as { extension_name: string };

  const { loading, value } = useAsync(async () => {
    try {
      const extension = await metablock.extensions.get(extension_name);
      const result = await metablock.spaces.getExtensions(space.id, {
        name: extension_name,
      });
      const spaceExtension = result.data.length ? result.data[0] : null;
      return { extension, spaceExtension };
    } catch (err) {
      return null;
    }
  }, [extension_name]);

  if (loading) return null;
  if (!value) return <NotFound />;
  const { extension, spaceExtension } = value;
  const title =
    spaceExtension === null
      ? `Add ${extension.name} extension`
      : `Update ${extension.name} extension`;
  const config = spaceExtension ? spaceExtension.config : null;

  const submit = async (data: any) => {
    data.name = extension.name;
    await metablock.spaces.updateExtension(space.id, data);
  };

  const getSchema = async () => {
    const schema: SchemaEntry = { properties: {}, type: "object" };
    if (extension.schema && schema.properties)
      schema.properties.config = extension.schema;
    return schema;
  };

  return (
    <Title title={title} tabs={tabs}>
      <Typography variant="body1" align="center" pb={2}>
        {extension.description}
      </Typography>
      <CrudForm
        defaults={{ config }}
        maxWidth={false}
        schema={getSchema}
        submit={submit}
      />
      {spaceExtension ? <ExtensionActions extension={spaceExtension} /> : null}
    </Title>
  );
};

export default ExtensionUpdate;
