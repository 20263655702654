import { Link } from "@metablock/react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React from "react";

const UserLinks = (props: any) => {
  return (
    <List sx={{}}>
      <ListItem>
        <Link to="/app">
          <Button variant="contained" color="inherit">
            Dashboard
          </Button>
        </Link>
      </ListItem>
    </List>
  );
};

export default UserLinks;
