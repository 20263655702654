import { Space } from "@metablock/core";
import { Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import Card from "../Components/Card";
import DangerZone from "../Components/DangerZone";
import SpaceUpdate from "./SpaceUpdate";

const SpaceActions = () => {
  const { spaceStore, messageStore } = useStores();
  const space = spaceStore.space as Space;
  const [deleted, setDeleted] = useState(false);

  if (deleted) {
    spaceStore.setSpace();
    messageStore.success(`Successfully deleted space ${space.id}`);
    return <Navigate to={`/app/orgs/${space.org_name}`} />;
  }

  const deleteSpace = async () => {
    await spaceStore.delete(space.id);
    if (spaceStore.errors) {
      messageStore.error("Could not delete space");
    } else setDeleted(true);
  };

  const transferSpace = () => {
    return true;
  };

  return (
    <Title>
      <Grid container spacing={4}>
        <Grid item sm={12} md={6}>
          <Card title="Update Space">
            <SpaceUpdate />
          </Card>
        </Grid>
        <Grid item sm={12} md={6}>
          <DangerZone
            items={[
              { text: "Delete Space", action: deleteSpace },
              { text: "Transfer Space", action: transferSpace },
            ]}
          />
        </Grid>
      </Grid>
    </Title>
  );
};

export default SpaceActions;
