import { Link, NoSsr, Page, SignIn, SignUp } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Navigate } from "react-router-dom";
import logos from "../Components/logos";
import ComingSoon from "./ComingSoon";

export const PageLogin = () => {
  const { authStore } = useStores();
  const onSuccess = () => <Navigate to="/app"></Navigate>;
  const Header = () => (
    <Box>
      <Box textAlign="center" pb={4}>
        <Link to="/">
          <img src={logos.avatarDark} height="80" />
        </Link>
      </Box>
      <Typography component="h1" variant="h5" paragraph>
        Sign in to Metablock
      </Typography>
    </Box>
  );

  return (
    <Page title="Sign in to Metablock">
      <NoSsr>
        <Box pt={10} pb={20}>
          <SignIn authStore={authStore} onSuccess={onSuccess} Header={Header} />
        </Box>
      </NoSsr>
    </Page>
  );
};

export const PageSignUp = (props: any) => {
  const params = new URL(location.href).searchParams;
  let jwt = params.get("jwt");
  if (jwt) jwt = null;
  return (
    <Page title="Join Metablock">
      {jwt ? <SignUp {...props} /> : <ComingSoon />}
    </Page>
  );
};
