import { Space } from "@metablock/core";
import { AppForm, CrudForm, Crumb, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Blocks from "../Views/Blocks";

const BlocksHome = ({ baseUrl, space }: { baseUrl: string; space: Space }) => {
  const tabs: Crumb[] = [
    {
      text: "all blocks",
      to: baseUrl,
    },
    {
      text: "new block",
      to: `${baseUrl}/new`,
    },
  ];
  return (
    <Routes>
      <Route path="" element={<SpaceBlocks tabs={tabs} space={space} />} />
      <Route path="new" element={<NewBlock tabs={tabs} space={space} />} />
    </Routes>
  );
};

export default BlocksHome;

const SpaceBlocks = ({ tabs, space }: { tabs: Crumb[]; space: Space }) => {
  const { metablock } = useStores();
  const api = metablock.spaces.blocksLoader(space.id);

  return <Blocks title={`${space.name} space blocks`} api={api} tabs={tabs} />;
};

const NewBlock = ({ tabs, space }: { tabs: Crumb[]; space: Space }) => {
  const { metablock, messageStore } = useStores();

  const submit = async (data: any) => {
    return await metablock.blocks.create({ ...data, space_id: space.id });
  };

  return (
    <Title title={`Add a new block to ${space.name}`} tabs={tabs}>
      <AppForm>
        <CrudForm
          maxWidth={false}
          schema={metablock.schemaLoader("BlockAdd")}
          submit={submit}
          onSuccess={(block) =>
            messageStore.success(`Block ${block.name} created`)
          }
          onError={(error) =>
            messageStore.error(`Failed to create block: ${error}`)
          }
        />
      </AppForm>
    </Title>
  );
};
