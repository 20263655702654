import { ApiDataGrid, ExtensionProps, Title } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import { gridSmall } from "@metaweb/theme";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { formatDateTime } from "../../utils/dates";
import SpaceClient from "../store";

const Orgs = ({ extension }: ExtensionProps) => {
  const { metablock } = useStores();
  const theme = useTheme();
  const client = new SpaceClient(metablock, extension.space_id);
  const api = client.orgsLoader();

  const columns = React.useMemo(
    () => [
      {
        key: "short_name",
        name: "Name",
      },
      {
        key: "id",
        name: "ID",
      },
      {
        key: "email",
        name: "Email",
      },
      {
        key: "full_name",
        name: "Description",
      },
      {
        key: "created",
        name: "Created",
        formatter: ({ row }: any) => (
          <Box component="span">{formatDateTime(new Date(row.created))}</Box>
        ),
      },
    ],
    []
  );

  return (
    <Title title={extension.description}>
      <ApiDataGrid api={api} columns={columns} {...gridSmall(theme)} />
    </Title>
  );
};

export default Orgs;
