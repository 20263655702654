import { Space } from "@metablock/core";
import { CheckBoxField, useForm } from "@metablock/react";
import { useStores } from "@metaweb/stores";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import React from "react";

const SpaceUpdate: React.FC = () => {
  const { spaceStore, messageStore } = useStores();
  const space = spaceStore.space as Space;

  const form = useForm({
    defaultValues: space,
    handleSubmit: async (data: any) => {
      await spaceStore.update(space.id, data);
      const errors = spaceStore.errors;
      if (errors && errors.status === 422) {
        form.setErrors(errors.errors, true);
      } else {
        messageStore.success(`Space ${space.domain} successfully updated`);
      }
    },
  });

  return (
    <form onSubmit={form.onSubmit()} noValidate>
      <CheckBoxField
        form={form}
        fullWidth
        margin="normal"
        color="default"
        name="hosted"
        label="metablock DNS hosting"
        helperText="Check this box if you need metablock to provide DNS your domain"
      />
      <CheckBoxField
        form={form}
        fullWidth
        margin="normal"
        color="default"
        name="users"
        label="Allow users blocks"
        helperText="Check this box if this service allows metablock users to add blocks to it"
      />
      <FormControl margin="normal">
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </FormControl>
    </form>
  );
};

export default SpaceUpdate;
